import { takeEvery, put, call, take, delay, all } from 'redux-saga/effects';
import * as API from 'services/api';
import {
  SELECT_OPTION,
  DOOR_LOAD_DOOR_REQUESTED, DOOR_LOAD_DOOR_FAILED,
  CONFIGURATOR_COMPONENT_REQUESTED, CONFIGURATOR_COMPONENT_SUCCEEDED,
  CONFIGURATOR_SELECT_DOOR_GLASS_PATTERN,
  CONFIGURATOR_SELECT_WORKING_WING,
  DOOR_LOAD_END,
} from 'consts/actionTypes';
import {
  NODE_DOOR_ACCESSORIES,
  TAXONOMY_TERM_DOOR_LOCATION,
  TAXONOMY_TERM_AVAILABLE_COLORS,
  TAXONOMY_TERM_DOOR_WING_TYPE,
  TAXONOMY_TERM_DOOR_OPENING_TYPE,
  NODE_DOOR_DESIGN,
  NODE_DOOR_DESIGN_EXTRA,
  TAXONOMY_TERM_DOOR_OPENING_DIRECTION,
  NODE_DOOR_PATTERN,
  NODE_DOOR_PATTERN_INSTANCE,
  TAXONOMY_TERM_DOOR_GLAZING_TYPE,
  NODE_DOOR_GLASS_PATTERN,
  NODE_DOOR_GLAZING,
  PASSIVE_WING,
} from 'consts/consts';
import { renderCanvas } from './canvasSagas';

let index = 0;

const countLoadedCompinents = () => {
  index += 1;
};

function* loadDoor(action) {
  try {
    yield take('CANVAS_INITIALIZED');

    const components = [
      [TAXONOMY_TERM_DOOR_LOCATION, '&include=fch_floor_multiply,fch_floor_screen,fch_objects,fch_wall_multiply,fch_wall_screen'],
      [TAXONOMY_TERM_AVAILABLE_COLORS, '&include=fch_horizontal_image,fch_vertical_image,fch_preview_image'],
      [TAXONOMY_TERM_DOOR_WING_TYPE, '&include=fch_dimension'],
      [TAXONOMY_TERM_DOOR_OPENING_TYPE],
      [NODE_DOOR_DESIGN, '&include=fch_dimension'],
      [NODE_DOOR_DESIGN_EXTRA, '&include=fch_dimension'],
      [TAXONOMY_TERM_DOOR_OPENING_DIRECTION],
      [NODE_DOOR_ACCESSORIES, '&include=fch_attached_image'],
      [NODE_DOOR_PATTERN, '&include=fch_colorable_parts'],
      [NODE_DOOR_PATTERN_INSTANCE, '&include=fch_attached_image'],
      [TAXONOMY_TERM_DOOR_GLAZING_TYPE, ''],
      [NODE_DOOR_GLASS_PATTERN, '&include=fch_attached_image,fch_preview_image'],
      [NODE_DOOR_GLAZING, '&include=fch_attached_image'],
    ];

    yield takeEvery(CONFIGURATOR_COMPONENT_SUCCEEDED, countLoadedCompinents);

    yield all(components.map((component) => (
      put({
        type: CONFIGURATOR_COMPONENT_REQUESTED,
        payload: {
          componentType: component[0],
          query: component[1],
        },
      })
    )));

    do {
      yield delay(1000);
    } while (index !== components.length);

    const doorId = action.payload.id;
    const doorProfile = yield call(API.getDoor, doorId);

    if (doorProfile.relationships.fch_design_extra.data) {
      yield put({
        type: SELECT_OPTION,
        payload: {
          property: 'selectedDoorDesignExtra',
          value: doorProfile.relationships.fch_design_extra.data[0].id,
        },
      });
    }

    if (doorProfile.relationships.fch_door_design.data) {
      yield put({
        type: SELECT_OPTION,
        payload: {
          property: 'selectedDoorDesign',
          value: doorProfile.relationships.fch_door_design.data.id,
        },
      });
    }

    if (doorProfile.relationships.fch_door_family.data) {
      yield put({
        type: SELECT_OPTION,
        payload: {
          property: 'selectedDoorFamily',
          value: doorProfile.relationships.fch_door_family.data.id,
        },
      });
    }

    if (doorProfile.relationships.fch_door_wing_type.data) {
      yield put({
        type: SELECT_OPTION,
        payload: {
          property: 'selectedDoorWingType',
          value: doorProfile.relationships.fch_door_wing_type.data.id,
        },
      });
    }

    if (doorProfile.relationships.fch_door_accessories.data) {
      yield put({
        type: 'CONFIGURATOR_SELECT_DOOR_ACCESSORY',
        payload: {
          selectedDoorAccessory: doorProfile.relationships.fch_door_accessories.data[0].id,
        },
      });
    }

    if (doorProfile.relationships.fch_door_location.data) {
      yield put({
        type: SELECT_OPTION,
        payload: {
          property: 'selectedLocation',
          value: doorProfile.relationships.fch_door_location.data.id,
        },
      });
    }

    if (doorProfile.relationships.fch_floor_color.data) {
      yield put({
        type: SELECT_OPTION,
        payload: {
          property: 'selectedFloorColor',
          value: doorProfile.relationships.fch_floor_color.data.id,
        },
      });
    }

    if (doorProfile.relationships.fch_wall_color.data) {
      yield put({
        type: SELECT_OPTION,
        payload: {
          property: 'selectedWallColor',
          value: doorProfile.relationships.fch_wall_color.data.id,
        },
      });
    }

    if (doorProfile.relationships.fch_door_opening_direction.data) {
      yield put({
        type: SELECT_OPTION,
        payload: {
          property: 'selectedDoorOpeningDirection',
          value: doorProfile.relationships.fch_door_opening_direction.data.id,
        },
      });
    }

    if (doorProfile.relationships.fch_door_pattern.data) {
      yield put({
        type: SELECT_OPTION,
        payload: {
          property: 'selectedDoorPatternActive',
          value: doorProfile.relationships.fch_door_pattern.data[0].id,
        },
      });
    }

    if (doorProfile.relationships.fch_door_pattern_instance.data) {
      yield put({
        type: SELECT_OPTION,
        payload: {
          property: 'selectedDoorPatternInstanceActive',
          value: doorProfile.relationships.fch_door_pattern_instance.data.id,
        },
      });
    }

    if (doorProfile.relationships.fch_door_pattern_passive.data) {
      yield put({
        type: SELECT_OPTION,
        payload: {
          property: 'selectedDoorPatternPassive',
          value: doorProfile.relationships.fch_door_pattern_passive.id,
        },
      });
    }

    if (doorProfile.relationships.fch_door_pattern_instance_passiv.data) {
      yield put({
        type: SELECT_OPTION,
        payload: {
          property: 'selectedDoorPatternInstancePassive',
          value: doorProfile.relationships.fch_door_pattern_instance_passiv.data.id,
        },
      });
    }

    if (doorProfile.relationships.fch_door_case_color.data) {
      yield put({
        type: SELECT_OPTION,
        payload: {
          property: 'selectedDoorFrameColor',
          value: doorProfile.relationships.fch_door_case_color.data.id,
        },
      });
    }

    if (doorProfile.relationships.fch_door_basic_color.data) {
      yield put({
        type: SELECT_OPTION,
        payload: {
          property: 'selectedDoorBodyColorActive',
          value: doorProfile.relationships.fch_door_basic_color.data.id,
        },
      });
    }

    if (doorProfile.relationships.fch_door_basic_color_passive.data) {
      yield put({
        type: SELECT_OPTION,
        payload: {
          property: 'selectedDoorBodyColorPassive',
          value: doorProfile.relationships.fch_door_basic_color_passive.data.id,
        },
      });
    }

    if (doorProfile.relationships.fch_intarsia_color.data) {
      yield put({
        type: SELECT_OPTION,
        payload: {
          property: 'selectedDoorInlay1ColorActive',
          value: doorProfile.relationships.fch_intarsia_color.data[0].id,
        },
      });
    }

    if (doorProfile.relationships.fch_intarsia_color_passive.data) {
      yield put({
        type: SELECT_OPTION,
        payload: {
          property: 'selectedDoorInlay1ColorPassive',
          value: doorProfile.relationships.fch_intarsia_color_passive.data[0].id,
        },
      });
    }

    if (doorProfile.relationships.fch_door_glazing_type.data && doorProfile.relationships.fch_door_glazing_type.data.length) {
      yield put({
        type: SELECT_OPTION,
        payload: {
          property: 'selectedDoorGlazingTypeActive',
          value: doorProfile.relationships.fch_door_glazing_type.data[0].id,
        },
      });
    }

    if (doorProfile.relationships.fch_door_glazing.data) {
      yield put({
        type: SELECT_OPTION,
        payload: {
          property: 'selectedDoorGlazingActive',
          value: doorProfile.relationships.fch_door_glazing.data.id,
        },
      });
    }

    if (doorProfile.relationships.fch_door_glazing_type_passive.data) {
      yield put({
        type: SELECT_OPTION,
        payload: {
          property: 'selectedDoorGlazingTypePassive',
          value: doorProfile.relationships.fch_door_glazing_type_passive.data.id,
        },
      });
    }

    if (doorProfile.relationships.fch_door_glazing_type_passive.data) {
      yield put({
        type: SELECT_OPTION,
        payload: {
          property: 'workingDoorGlazingTypePassive',
          value: doorProfile.relationships.fch_door_glazing_type_passive.data.id,
        },
      });
    }

    if (doorProfile.relationships.fch_door_glazing_passive.data) {
      yield put({
        type: SELECT_OPTION,
        payload: {
          property: 'selectedDoorGlazingPassive',
          value: doorProfile.relationships.fch_door_glazing_passive.data.id,
        },
      });
    }

    if (doorProfile.relationships.fch_door_glass_pattern.data) {
      yield put({
        type: CONFIGURATOR_SELECT_DOOR_GLASS_PATTERN,
        payload: {
          selectedDoorGlassPattern: doorProfile.relationships.fch_door_glass_pattern.data.id,
        },
      });
    }

    yield delay(3000);
    yield put({
      type: CONFIGURATOR_SELECT_WORKING_WING,
      payload: {
        selectedWorkingWing: PASSIVE_WING,
      },
    });

    if (doorProfile.relationships.fch_door_glass_pattern_passive.data) {
      yield put({
        type: CONFIGURATOR_SELECT_DOOR_GLASS_PATTERN,
        payload: {
          selectedDoorGlassPattern: doorProfile.relationships.fch_door_glass_pattern_passive.data.id,
        },
      });
    }

    yield call(renderCanvas);
    yield put({ type: DOOR_LOAD_END });
  } catch (error) {
    console.log(error); // eslint-disable-line
    yield put({
      type: DOOR_LOAD_DOOR_FAILED,
    });
  }
}

export default [
  takeEvery(DOOR_LOAD_DOOR_REQUESTED, loadDoor),
];
