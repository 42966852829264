/* eslint-disable max-len */

// import doorAvailableLocation from './apiData/doorAvailableLocation';
// import doorAvailableColors from './apiData/doorAvailableColors';
// import doorAvailableColorsAttachedImage from './apiData/doorAvailableColorsAttachedImage';
// import doorWingType from './apiData/doorWingType';
// import doorOpeningDirection from './apiData/doorOpeningDirection';
// import doorDesign from './apiData/doorDesign';
// import doorDesignExtra from './apiData/doorDesignExtra';
import doorDimensions from './apiData/doorDimensions';
// import doorGlassPattern from './apiData/doorGlassPattern';
// import doorGlassPatternAttachedImage from './apiData/doorGlassPatternAttachedImage';
// import doorGlazingType from './apiData/doorGlazingType';
// import doorGlazing from './apiData/doorGlazing';
// import doorGlazingAttachedImage from './apiData/doorGlazingAttachedImage';
// import doorPattern from './apiData/doorPattern';
// import doorPatternInstance from './apiData/doorPatternInstance';
// import doorPatternInstanceAttachedImage from './apiData/doorPatternInstanceAttachedImage';

const initialState = {
  doorFamily: {
    '99765159-902e-442b-a677-3f3da2613e9b': {
      type: 'node--door_family',
      id: '99765159-902e-442b-a677-3f3da2613e9b',
      attributes: {
        drupal_internal__nid: 32,
        drupal_internal__vid: 32,
        langcode: 'en',
        revision_timestamp: '2019-07-12T08:39:23+00:00',
        revision_log: null,
        status: true,
        title: 'CPL',
        created: '2019-07-12T08:39:23+00:00',
        changed: '2019-07-12T08:39:23+00:00',
        promote: true,
        sticky: false,
        default_langcode: true,
        revision_translation_affected: true,
        path: {
          alias: null,
          pid: null,
          langcode: 'en',
        },
        fch_color_components_name: null,
        fch_default_wall_thickness: null,
      },
      relationships: {
        node_type: {
          data: {
            type: 'node_type--node_type',
            id: '9ef4c0f4-dc57-41fd-bdad-b300c37ab387',
          },
          links: {
            self: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_family/99765159-902e-442b-a677-3f3da2613e9b/relationships/node_type?resourceVersion=id%3A32',
            },
            related: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_family/99765159-902e-442b-a677-3f3da2613e9b/node_type?resourceVersion=id%3A32',
            },
          },
        },
        revision_uid: {
          data: {
            type: 'user--user',
            id: 'f7e03635-2506-48ad-8e96-4adfc953e08d',
          },
          links: {
            self: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_family/99765159-902e-442b-a677-3f3da2613e9b/relationships/revision_uid?resourceVersion=id%3A32',
            },
            related: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_family/99765159-902e-442b-a677-3f3da2613e9b/revision_uid?resourceVersion=id%3A32',
            },
          },
        },
        uid: {
          data: {
            type: 'user--user',
            id: 'f7e03635-2506-48ad-8e96-4adfc953e08d',
          },
          links: {
            self: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_family/99765159-902e-442b-a677-3f3da2613e9b/relationships/uid?resourceVersion=id%3A32',
            },
            related: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_family/99765159-902e-442b-a677-3f3da2613e9b/uid?resourceVersion=id%3A32',
            },
          },
        },
        fch_available_colors: {
          data: [
            {
              type: 'taxonomy_term--available_colors',
              id: 'ecd998c0-25e8-4b7b-9dba-8ad8edd4032c',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '691a01ed-314f-46eb-9427-db9d75977a0a',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'b9a36ac7-129e-4207-a17a-2b06e7bcd48e',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '538987eb-fec4-4b7d-b0b1-2c63509c930c',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'ed8948f2-18d7-473e-9841-5e6bd6043da8',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'ee83a0cf-36a7-4401-ae7b-02db86ff4657',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '933dd0ef-1c31-4db2-b438-d0220362c9e7',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '927af43e-a0d5-4185-b2da-bb00a431dff4',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'ca63d022-ad62-4dc3-b394-715095d4e19f',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '333c0eb4-2ff0-403a-ae13-5bba0b16e801',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'a6792129-f51a-4f2a-a2b0-5bfe0961a610',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'e3677031-c088-40dd-b162-4a2938f8328c',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '9ac3acbc-bda5-4a32-802e-2418dc5472f9',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '044aeced-c877-4447-9356-23da3a530b05',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '570a29b0-0404-452b-bec4-8ff9a4a7cad8',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '5a6fe425-ee0b-494a-a8a1-02aad1a7ac04',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'cdd5ef0a-eac8-49d5-91cf-91147c2ca5df',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'de724125-5277-40c3-83ba-7cf544439163',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'e7890fdc-48b0-404f-ab79-349ca0b3bcf1',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '22f4abf9-f394-437f-8835-d9df514ea5f0',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '9df6d9f1-cfc6-4dc4-97ef-86032812e6de',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '866898c3-8c35-4aef-8d8a-0bd7e91c744a',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '0e2a9ab2-eab6-4367-8c2e-e099f46e9716',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'a2eae6a1-5772-470b-aff0-7f476f7d499e',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '6f3f5ece-141b-4a44-b4d6-75fd9794e38b',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'f4091705-36fb-4978-8e23-bc1c4b0a47b2',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'b42ed536-26cd-4793-97f7-46a13841e9ce',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '779b0f77-4b67-4047-833a-d4eb49796ddf',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '8b9d400a-ae35-4185-86e1-5ae39375b6a0',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '10897a24-1985-41e2-9776-b3e47ceb99e0',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'ffae7a5e-f79c-4ac8-9ef8-5370b1c69f86',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '98a2b4b4-1daa-48fa-ad0e-39e7013c779b',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'add20f19-f379-4e28-9280-56f19dab0b22',
            },
          ],
          links: {
            self: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_family/99765159-902e-442b-a677-3f3da2613e9b/relationships/fch_available_colors?resourceVersion=id%3A32',
            },
            related: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_family/99765159-902e-442b-a677-3f3da2613e9b/fch_available_colors?resourceVersion=id%3A32',
            },
          },
        },
        fch_door_pattern: {
          data: [
            {
              type: 'node--door_pattern',
              id: 'f8ce5cbd-a10c-4b51-9bce-e69268d88cde',
            },
            {
              type: 'node--door_pattern',
              id: 'b7189662-f7bb-44e9-9bec-cbd44ea5d34e',
            },
            {
              type: 'node--door_pattern',
              id: '71e6154e-c02c-49f4-9995-5f80413fa0d8',
            },
            {
              type: 'node--door_pattern',
              id: 'bcf16d71-f184-4b76-b393-07086a984331',
            },
            {
              type: 'node--door_pattern',
              id: '133397af-6389-4189-b4b4-cc054a51efb3',
            },
          ],
          links: {
            self: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_family/99765159-902e-442b-a677-3f3da2613e9b/relationships/fch_door_pattern?resourceVersion=id%3A32',
            },
            related: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_family/99765159-902e-442b-a677-3f3da2613e9b/fch_door_pattern?resourceVersion=id%3A32',
            },
          },
        },
      },
      links: {
        self: {
          href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_family/99765159-902e-442b-a677-3f3da2613e9b?resourceVersion=id%3A32',
        },
      },
    },
    '44983000-e1d8-496c-b47c-0248388f18b0': {
      type: 'node--door_family',
      id: '44983000-e1d8-496c-b47c-0248388f18b0',
      attributes: {
        drupal_internal__nid: 33,
        drupal_internal__vid: 33,
        langcode: 'en',
        revision_timestamp: '2019-07-12T08:39:23+00:00',
        revision_log: null,
        status: true,
        title: 'Festett',
        created: '2019-07-12T08:39:23+00:00',
        changed: '2019-07-12T08:39:23+00:00',
        promote: true,
        sticky: false,
        default_langcode: true,
        revision_translation_affected: true,
        path: {
          alias: null,
          pid: null,
          langcode: 'en',
        },
        fch_color_components_name: null,
        fch_default_wall_thickness: null,
      },
      relationships: {
        node_type: {
          data: {
            type: 'node_type--node_type',
            id: '9ef4c0f4-dc57-41fd-bdad-b300c37ab387',
          },
          links: {
            self: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_family/44983000-e1d8-496c-b47c-0248388f18b0/relationships/node_type?resourceVersion=id%3A33',
            },
            related: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_family/44983000-e1d8-496c-b47c-0248388f18b0/node_type?resourceVersion=id%3A33',
            },
          },
        },
        revision_uid: {
          data: {
            type: 'user--user',
            id: 'f7e03635-2506-48ad-8e96-4adfc953e08d',
          },
          links: {
            self: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_family/44983000-e1d8-496c-b47c-0248388f18b0/relationships/revision_uid?resourceVersion=id%3A33',
            },
            related: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_family/44983000-e1d8-496c-b47c-0248388f18b0/revision_uid?resourceVersion=id%3A33',
            },
          },
        },
        uid: {
          data: {
            type: 'user--user',
            id: 'f7e03635-2506-48ad-8e96-4adfc953e08d',
          },
          links: {
            self: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_family/44983000-e1d8-496c-b47c-0248388f18b0/relationships/uid?resourceVersion=id%3A33',
            },
            related: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_family/44983000-e1d8-496c-b47c-0248388f18b0/uid?resourceVersion=id%3A33',
            },
          },
        },
        fch_available_colors: {
          data: [
            {
              type: 'taxonomy_term--available_colors',
              id: '384fece3-d944-447e-a2d2-7ead8e59732e',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '99bab098-beb8-444a-9e3b-f92c73af0b81',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '4ce51803-076b-49fc-9f1f-3bee2f245b53',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'd3a54e89-335c-4da4-8b79-a18f2a902d02',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '6088935f-063b-4370-9080-d96a6dec29cc',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '41df8cb2-5564-4023-8bec-3b80e98acc28',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '9f26d5fe-7016-4522-804e-a218b6c314d6',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '738dda80-4d89-4a1b-b888-0295574ec251',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'ab975b14-a0e0-4512-9b70-11eca236de2f',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'fcfde787-df3d-49df-ac35-211fe9b608ea',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '711d6d5e-4de7-4238-be5b-34080c25f652',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '31f9afbe-4f7b-4ce0-bc40-471dcef0ecbe',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '9ccad6aa-9834-4c02-9f38-128366ae567a',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'c22164fc-b53f-4a83-b434-c60fda32b677',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'a595450b-421e-4c4f-b9f9-85ace1c19436',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'fd04d858-6d1d-48d8-89eb-9ddaee4938c5',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '25660312-7ed7-42b7-8765-bcffecaf3467',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '7429d0bd-d0ca-484f-bd9f-ac82862c2adb',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '8b2618f8-effd-4579-a229-56b08cb84272',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'ae2035aa-e2b2-4dc4-b4f4-02d72889402c',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '53d3cecd-162d-4497-b6e9-2d044f9c2fd3',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '48962a68-b784-4c94-a210-5c5700560ca2',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '6d8b9e16-e98b-4ce0-a74e-c2ea052cf159',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '42600a37-50c3-48a5-88a6-b9e857629dc9',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '5e9f7771-3a5e-4afb-ae19-448349d866b3',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'fc8e432a-6056-4188-ab07-24290ae5e8ad',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'efa2742e-f692-44bb-992f-4882fa420214',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '567c1dbe-cf19-4217-ad33-8e73490df458',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '057beff2-9a56-4137-abaf-1da8c5ccb31d',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '3add5e63-906d-4948-93e6-dde42d93c234',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'dd6ffacc-35a1-4d8a-9af9-c7b800281b5a',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '6ed0d882-c5c5-410c-b2ae-17f30c4609c5',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '6f3b3d57-f8c6-4823-88c8-9ca65ae6fbe1',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'e4eed5b9-193b-4f70-80f4-ae672d9ea472',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'c11936ce-d308-440c-a13b-86e0400af60a',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'a95c6d50-781a-4166-9a10-facd314cd361',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '9fd9de37-7a23-46a1-8de7-e8f4d7958234',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'a3d6b92a-a274-47d9-955a-caa631c8348c',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '9bebb5fe-2be0-427a-83e7-2dfa4d1c340d',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'd0c4f4b5-eeae-4cfb-9736-28f202e38b97',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'a3722ba1-7da4-46ab-94f0-f06d02503be9',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'd7497592-0e13-43f9-af28-33e042564522',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '33547256-ad01-4680-9257-d27bee942f03',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '8b5193fb-c95e-4670-bde0-25ba3553c27e',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '9d47b5c9-c345-415e-912c-eddcde8d1bc0',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'c0a26e2e-ea53-4162-8550-7e5407136716',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'bee6af4c-790b-4c7a-be45-f1c0cfaecd4f',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '74ee9950-2a49-477a-a8b4-499bd0676ded',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '42bdab22-55c8-4882-af4b-e171e7cb6c42',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '97dfa997-bbb8-41e1-b1a8-9409cd4b7b00',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '235ae1c4-fa5f-4870-8ed0-74a32da950ec',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '8e2ba8b2-14d1-41fc-9b38-ff1c0871fd74',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '395d3d41-4bb0-4d1c-935c-1e9fdd8a1eb1',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '19a26ab6-0f06-46c1-96ec-741653d245d5',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '57898914-3925-4553-9e7d-b5bd25c6298f',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '183dce0d-559e-4390-91da-08999b426ed1',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'ebd3f920-8645-4079-b8d7-c4dffa4f1884',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'be30d62e-b745-44ae-b8b9-f5f7e5fcfe3d',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '8b731da2-6d45-4500-b533-7cd53d4dcba3',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'c9faae15-f09b-47e1-84eb-c14415345949',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '71f2333f-8d94-4240-a593-46e77aa38bfa',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'ed7495c6-f9c4-48b6-a992-876eb0a4e520',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'c43be69c-436f-4679-973d-04f052887a93',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '27e1a157-0ff9-4777-a89e-60039d21077f',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '3eae630f-dc6d-4a00-89cf-e8b91212463c',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'd0fc7643-f414-48ee-aede-f664af27d1da',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '3929e34d-eb14-43eb-b84b-fd8e03c721df',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '810fd505-2f29-4058-a30e-3881a76a8232',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'd81f8848-5965-43c1-ac2c-462e782d038c',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '2ffb1043-f70a-413c-b7e7-2d608c67c3a9',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '9ba6ab81-906c-44ee-aa71-8d96f75b228a',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'cc4a6ffb-5ce3-4e0c-aa35-fa1731f3a874',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '9684898b-bbd3-4017-80b0-d0575889be0a',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '2345638b-4dc0-4eb5-89dc-f6f75eca7ac8',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '2543518b-5c70-4485-ac26-535c6b9d2656',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '5ffab179-6323-4888-a8f4-f9fba2223ecb',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '723d9be2-d1d4-4f37-91c4-535a15bf058c',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '12557954-7992-4a2c-88cf-bdd8963904bb',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '9ab14160-eade-4f67-ae45-675f96e6f34f',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '502a1b1e-d127-4775-8611-6d3237e5fcc5',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '52cf9d99-0549-4988-8f36-7626836b4afb',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '0d490c0b-6922-4e58-a7b7-52ce4c234d4c',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '47c9284d-3e3a-4800-bc0e-5f2914963c2d',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '388b8791-7174-4058-ba33-aed04faaa984',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'afe380ef-37d5-4339-bc37-3c9f252f34d2',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '65e3240c-5955-451c-be6f-ab573a79b5bc',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '3085d2c3-b0c8-45b8-9e25-4ed82b0f72fb',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '0c705470-4dd0-4319-bbe7-7e04066624ae',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '3047a520-95e0-455a-aa5e-89a8e3aef21f',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'ad89bdd9-eb54-4072-84b4-a543dbb0a1a1',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '49040dc2-9794-49f7-9d91-c8d1d2b7969f',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'e8d181eb-f546-4e76-bb9e-496572907a58',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'a5ea3e4a-4e98-47ef-a8a2-a29e89938fa1',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'fb456448-728e-4578-ae2a-c9d672c40cf6',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'd2898a51-296c-4f73-bebd-e41e56fb0fa7',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '674cd4a2-4827-4cc7-84ac-630eac24f961',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'b4e3b6a6-41ec-448c-ba63-b0b6fb89406b',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '7ec6dc25-b0b2-431b-b756-09fa31311adc',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '457cc3a0-a7e1-4f30-9e7a-3df1bd4878e9',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '751ff737-fa04-4baf-9f4a-4f02b7a23ebb',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'e66a0da3-c590-4fe9-bf61-40c87242b9a2',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'ec5cc2d8-49b7-43e4-85a4-87f25cf9a78f',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '6267d834-664b-42d3-8cfb-f3d00d12717b',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '262f9e1a-2db7-4b28-9793-fd563d0e4d22',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '67dd3b4d-6ffe-4005-a5c2-c7ff6e727dca',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'f8ff2979-a3d5-4a6a-8362-552277a2e009',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '91f973b4-f9c3-4ea5-80d6-b1f38ece7472',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '8a03a80d-f958-4475-8f8a-10ab66ef9bce',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '23bc969b-3571-4c36-890f-25fa73d2321e',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'df4844e2-858a-4cdd-87c3-35527236cdd5',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '6206adcb-f328-40e2-8809-ab3c29f9a8f9',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'ccc83fe5-8949-426d-af12-1d64bc512a8e',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '5bac2f0d-43e9-40ce-a437-22c9fa1ef37f',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '4576ff3c-1998-4c36-bbdc-5f043043e795',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '5d84e8a8-3c1d-46c6-a1f2-9f651010b037',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'c04efffd-b02c-4cb0-a0f4-ec35f3c490d3',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '17773723-752b-4bb7-a88a-f66028199e76',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'e469a983-b602-418e-92aa-40503ddaaaba',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '3c8fbb92-d806-47db-8054-dfd0ba967b85',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '8a0911ed-46e9-4339-aadc-c072015d1335',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '09096033-0bbf-478b-a9be-c8eec1852bbd',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'c8a9917e-0514-440d-b109-3dc7219128ea',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '2465cd16-2b10-4206-ba58-4884a2131e76',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '701152e9-c7aa-48ce-965b-92a492b1b3dc',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'd1733e13-72f1-44bb-afed-3098622dfde2',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '6260abb9-b259-46fa-b8bd-73e228829798',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '9869b951-7227-41d8-b589-4ccd6d119d2f',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'fe21746d-b7e0-48d7-b6e6-55ef87de4269',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'f9ebac1c-4ed2-442a-9a94-ba232add307d',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '2c5b2ff3-78a4-4077-b46c-7f79024be751',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'a523f1cf-c7fe-4a9c-a5d7-f10dc11ec394',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '619b1f93-843d-4410-b8d4-3cbca18373c0',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '82ca2c0b-df35-4fd1-b694-660a7465f268',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '530c3e83-b709-4651-ada1-38c25c0d5234',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '023e9e87-cc0c-45af-9394-d43208cff1bf',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'c68115a7-b4b9-4da2-8f63-f72db0c4eb51',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'fb8c4832-ddb4-43df-9334-2d72049bcd9e',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'c989813c-ece2-4576-a7ca-7759c96eee1b',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '7388846b-a48b-458b-bb79-2952aab8f34e',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '3d7d5176-4d8f-4d17-b7b3-f6dd6649574b',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'ddd3f58c-35ab-4bdf-921d-80913e570017',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '0e8ecf45-28cd-4d91-b83a-d7d4ad9941ff',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '1cf5e316-5979-4520-aba1-bff36c76ceff',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '9bd92bd4-493e-43b4-89c8-ae62733d793d',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '7364f725-3b39-4c58-bf5c-ccfb0b76cc3e',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '9d76cb6c-eea9-4b3f-9927-755d383bd1fc',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '949b5a12-8598-42e7-bd8c-0282cba6941e',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'da867bd9-018a-47b3-a28c-d81ed0503eb2',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '4d273f59-0043-4878-9063-fc56d4d8c97e',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'f7c91f81-6e0a-47ab-885e-b6bda340773c',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '9c34be6b-de33-40c5-8bf1-3805d0b46473',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '406ba771-b4c3-4241-ba66-dbc0bbb041d1',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '15022201-864d-4a38-aa79-0ebf6687a125',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '6ca5dfbe-79cf-4c04-a56c-becbb318b570',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'a980517c-f76e-45ec-a40f-051df3678ef4',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '55472b45-9bf6-47f7-b3d7-04cf83619fc0',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '52cd0e3a-4c62-478a-8579-e5cc83af9c4f',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'a82fe2de-5c7a-410d-951e-9473c09c36fa',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '438c9211-5482-41f2-8226-296db50b5a1d',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '6e4b45f4-63f3-4bdf-9a6f-157ea877eb36',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '959e141f-0b74-4896-b6b0-e44ca40c7b82',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'ccf1c2f1-01b5-4e39-9a7d-7933bfc7aa51',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '5757d02d-f0af-4dce-955c-46463a9bcf78',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '57c8b95e-d7b1-4743-b374-d546d118373a',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '6caf91b5-fd33-42ba-b152-2aed07fee264',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '530fd383-318a-4843-9128-8e723f014549',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '060d19c1-01b2-4ccd-af7f-25dd9d069e82',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '374b4dcb-5b71-43ba-a7b4-9904afa89d6f',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'd712578b-14c4-45b1-ad53-80d02c0dfcc3',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'c64f959f-9a9b-41c2-a333-6adc510c085a',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '075aad4c-b3ec-4327-af6a-c480baa76390',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'b79078bc-740e-4655-a014-f53ad6ce680d',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'aac31a82-5868-48f0-a0dc-5d92ac78f0b6',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '6e6f8b71-c770-4486-846a-2ee38e07760a',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '05c71b98-69fc-4fa7-848b-30eb3501d1c6',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '188ec38a-38db-4bf2-b83b-f88f9813ff05',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'e5c33e4e-f8c7-4765-a874-2119046eb87a',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'b91d46d4-44a6-45dc-a9d0-85045165c882',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'ac506c68-7b12-464b-b3ff-93adb56a6424',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'd22d6a6e-573d-48b9-ac06-333c8653fbd9',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'e655110b-6285-4a65-b5b2-711ca07c2a11',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '58698d3e-42d4-40a4-8b5d-c66e772da640',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '2cfea63b-d8da-439d-a410-b352e53c2cbd',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '61649adc-7b73-47cc-b48d-8e7146445c55',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'ce8fb423-53e8-40d4-afe7-0138b3c91d40',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'e8cba398-fee2-4c74-bbf4-6e5a10abe81f',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: 'f1f5b327-5355-4a23-b904-93979a3a483b',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '37119f85-4088-4926-a0b6-209ceea7551e',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '4cac7d25-25bf-4c80-bb87-ed596783f855',
            },
            {
              type: 'taxonomy_term--available_colors',
              id: '004cd475-9f3f-4ad1-92f2-2bba733fd6c7',
            },
          ],
          links: {
            self: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_family/44983000-e1d8-496c-b47c-0248388f18b0/relationships/fch_available_colors?resourceVersion=id%3A33',
            },
            related: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_family/44983000-e1d8-496c-b47c-0248388f18b0/fch_available_colors?resourceVersion=id%3A33',
            },
          },
        },
        fch_door_pattern: {
          data: [
            {
              type: 'node--door_pattern',
              id: 'f8ce5cbd-a10c-4b51-9bce-e69268d88cde',
            },
            {
              type: 'node--door_pattern',
              id: 'bcf16d71-f184-4b76-b393-07086a984331',
            },
          ],
          links: {
            self: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_family/44983000-e1d8-496c-b47c-0248388f18b0/relationships/fch_door_pattern?resourceVersion=id%3A33',
            },
            related: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_family/44983000-e1d8-496c-b47c-0248388f18b0/fch_door_pattern?resourceVersion=id%3A33',
            },
          },
        },
      },
      links: {
        self: {
          href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_family/44983000-e1d8-496c-b47c-0248388f18b0?resourceVersion=id%3A33',
        },
      },
    },
  },
  doorAvailableLocation: {},
  doorAvailableColors: {
    // fenyő '927af43e-a0d5-4185-b2da-bb00a431dff4': {
    '5a6fe425-ee0b-494a-a8a1-02aad1a7ac04': {
      type: 'taxonomy_term--available_colors',
      id: '5a6fe425-ee0b-494a-a8a1-02aad1a7ac04',
      attributes: {
        drupal_internal__tid: 208,
        drupal_internal__revision_id: 208,
        langcode: 'en',
        revision_created: '2019-11-06T14:31:59+00:00',
        revision_log_message: null,
        status: true,
        name: 'Fehér Aland Fenyő',
        description: null,
        weight: 0,
        changed: '2019-11-06T14:31:59+00:00',
        default_langcode: true,
        revision_translation_affected: true,
        path: {
          alias: null,
          pid: null,
          langcode: 'en',
        },
        fch_type: true,
      },
      relationships: {
        vid: {
          data: {
            type: 'taxonomy_vocabulary--taxonomy_vocabulary',
            id: '0e4eedd6-4f4f-429c-820b-23a0de1d404f',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/5a6fe425-ee0b-494a-a8a1-02aad1a7ac04/relationships/vid',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/5a6fe425-ee0b-494a-a8a1-02aad1a7ac04/vid',
            },
          },
        },
        revision_user: {
          data: null,
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/5a6fe425-ee0b-494a-a8a1-02aad1a7ac04/relationships/revision_user',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/5a6fe425-ee0b-494a-a8a1-02aad1a7ac04/revision_user',
            },
          },
        },
        parent: {
          data: [
            {
              type: 'taxonomy_term--available_colors',
              id: 'virtual',
              meta: {
                links: {
                  help: {
                    href: 'https://www.drupal.org/docs/8/modules/json-api/core-concepts#virtual',
                    meta: {
                      about: "Usage and meaning of the 'virtual' resource identifier.",
                    },
                  },
                },
              },
            },
          ],
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/5a6fe425-ee0b-494a-a8a1-02aad1a7ac04/relationships/parent',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/5a6fe425-ee0b-494a-a8a1-02aad1a7ac04/parent',
            },
          },
        },
        fch_horizontal_image: {
          data: {
            type: 'file--image',
            id: '62f5df85-2162-44e7-ba53-c3af4f9655c9',
            meta: {
              alt: null,
              title: null,
              width: 2734,
              height: 1133,
            },
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/5a6fe425-ee0b-494a-a8a1-02aad1a7ac04/relationships/fch_horizontal_image',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/5a6fe425-ee0b-494a-a8a1-02aad1a7ac04/fch_horizontal_image',
            },
          },
        },
        fch_preview_image: {
          data: {
            type: 'file--image',
            id: '12c391f7-0d28-4dae-938f-4fe611823acf',
            meta: {
              alt: null,
              title: null,
              width: 120,
              height: 120,
            },
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/5a6fe425-ee0b-494a-a8a1-02aad1a7ac04/relationships/fch_preview_image',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/5a6fe425-ee0b-494a-a8a1-02aad1a7ac04/fch_preview_image',
            },
          },
        },
        fch_vertical_image: {
          data: {
            type: 'file--image',
            id: '52aa6ad0-975d-4654-bce1-5310bc18cded',
            meta: {
              alt: null,
              title: null,
              width: 1133,
              height: 2734,
            },
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/5a6fe425-ee0b-494a-a8a1-02aad1a7ac04/relationships/fch_vertical_image',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/5a6fe425-ee0b-494a-a8a1-02aad1a7ac04/fch_vertical_image',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/5a6fe425-ee0b-494a-a8a1-02aad1a7ac04',
        },
      },
    },
    // szilva
    '9d9cc9bf-b1bd-48b1-9aeb-5c7093f22daf': {
      type: 'taxonomy_term--available_colors',
      id: '9d9cc9bf-b1bd-48b1-9aeb-5c7093f22daf',
      attributes: {
        drupal_internal__tid: 232,
        drupal_internal__revision_id: 232,
        langcode: 'en',
        revision_created: '2019-11-06T14:31:59+00:00',
        revision_log_message: null,
        status: true,
        name: 'Vörös Szilva',
        description: null,
        weight: 0,
        changed: '2019-11-06T14:31:59+00:00',
        default_langcode: true,
        revision_translation_affected: true,
        path: {
          alias: null,
          pid: null,
          langcode: 'en',
        },
        fch_type: true,
      },
      relationships: {
        vid: {
          data: {
            type: 'taxonomy_vocabulary--taxonomy_vocabulary',
            id: '0e4eedd6-4f4f-429c-820b-23a0de1d404f',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/9d9cc9bf-b1bd-48b1-9aeb-5c7093f22daf/relationships/vid',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/9d9cc9bf-b1bd-48b1-9aeb-5c7093f22daf/vid',
            },
          },
        },
        revision_user: {
          data: null,
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/9d9cc9bf-b1bd-48b1-9aeb-5c7093f22daf/relationships/revision_user',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/9d9cc9bf-b1bd-48b1-9aeb-5c7093f22daf/revision_user',
            },
          },
        },
        parent: {
          data: [
            {
              type: 'taxonomy_term--available_colors',
              id: 'virtual',
              meta: {
                links: {
                  help: {
                    href: 'https://www.drupal.org/docs/8/modules/json-api/core-concepts#virtual',
                    meta: {
                      about: "Usage and meaning of the 'virtual' resource identifier.",
                    },
                  },
                },
              },
            },
          ],
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/9d9cc9bf-b1bd-48b1-9aeb-5c7093f22daf/relationships/parent',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/9d9cc9bf-b1bd-48b1-9aeb-5c7093f22daf/parent',
            },
          },
        },
        fch_horizontal_image: {
          data: {
            type: 'file--image',
            id: '2184ebc8-2630-444f-b4f3-4bf6a77ddd73',
            meta: {
              alt: null,
              title: null,
              width: 4528,
              height: 2093,
            },
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/9d9cc9bf-b1bd-48b1-9aeb-5c7093f22daf/relationships/fch_horizontal_image',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/9d9cc9bf-b1bd-48b1-9aeb-5c7093f22daf/fch_horizontal_image',
            },
          },
        },
        fch_preview_image: {
          data: {
            type: 'file--image',
            id: 'f58ffebf-6cbb-4b2a-bf6e-fa2d93d27ec1',
            meta: {
              alt: null,
              title: null,
              width: 120,
              height: 120,
            },
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/9d9cc9bf-b1bd-48b1-9aeb-5c7093f22daf/relationships/fch_preview_image',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/9d9cc9bf-b1bd-48b1-9aeb-5c7093f22daf/fch_preview_image',
            },
          },
        },
        fch_vertical_image: {
          data: {
            type: 'file--image',
            id: '1256f97e-2380-4684-850f-c3aefbf1342a',
            meta: {
              alt: null,
              title: null,
              width: 2093,
              height: 4528,
            },
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/9d9cc9bf-b1bd-48b1-9aeb-5c7093f22daf/relationships/fch_vertical_image',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/9d9cc9bf-b1bd-48b1-9aeb-5c7093f22daf/fch_vertical_image',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/9d9cc9bf-b1bd-48b1-9aeb-5c7093f22daf',
        },
      },
    },
    // juhar
    '779b0f77-4b67-4047-833a-d4eb49796ddf': {
      type: 'taxonomy_term--available_colors',
      id: '779b0f77-4b67-4047-833a-d4eb49796ddf',
      attributes: {
        drupal_internal__tid: 219,
        drupal_internal__revision_id: 219,
        langcode: 'en',
        revision_created: '2019-11-06T14:31:59+00:00',
        revision_log_message: null,
        status: true,
        name: 'Natúr Juhar',
        description: null,
        weight: 0,
        changed: '2019-11-06T14:31:59+00:00',
        default_langcode: true,
        revision_translation_affected: true,
        path: {
          alias: null,
          pid: null,
          langcode: 'en',
        },
        fch_type: true,
      },
      relationships: {
        vid: {
          data: {
            type: 'taxonomy_vocabulary--taxonomy_vocabulary',
            id: '0e4eedd6-4f4f-429c-820b-23a0de1d404f',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/779b0f77-4b67-4047-833a-d4eb49796ddf/relationships/vid',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/779b0f77-4b67-4047-833a-d4eb49796ddf/vid',
            },
          },
        },
        revision_user: {
          data: null,
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/779b0f77-4b67-4047-833a-d4eb49796ddf/relationships/revision_user',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/779b0f77-4b67-4047-833a-d4eb49796ddf/revision_user',
            },
          },
        },
        parent: {
          data: [
            {
              type: 'taxonomy_term--available_colors',
              id: 'virtual',
              meta: {
                links: {
                  help: {
                    href: 'https://www.drupal.org/docs/8/modules/json-api/core-concepts#virtual',
                    meta: {
                      about: "Usage and meaning of the 'virtual' resource identifier.",
                    },
                  },
                },
              },
            },
          ],
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/779b0f77-4b67-4047-833a-d4eb49796ddf/relationships/parent',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/779b0f77-4b67-4047-833a-d4eb49796ddf/parent',
            },
          },
        },
        fch_horizontal_image: {
          data: {
            type: 'file--image',
            id: 'f260a371-f1db-4cc7-b3ec-181e4a78a58d',
            meta: {
              alt: null,
              title: null,
              width: 2038,
              height: 875,
            },
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/779b0f77-4b67-4047-833a-d4eb49796ddf/relationships/fch_horizontal_image',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/779b0f77-4b67-4047-833a-d4eb49796ddf/fch_horizontal_image',
            },
          },
        },
        fch_preview_image: {
          data: {
            type: 'file--image',
            id: '5c93cfb4-3f66-48c1-abaf-076045cd0f33',
            meta: {
              alt: null,
              title: null,
              width: 120,
              height: 120,
            },
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/779b0f77-4b67-4047-833a-d4eb49796ddf/relationships/fch_preview_image',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/779b0f77-4b67-4047-833a-d4eb49796ddf/fch_preview_image',
            },
          },
        },
        fch_vertical_image: {
          data: {
            type: 'file--image',
            id: '9f8c1202-39bc-4753-88dc-8f30c2022abc',
            meta: {
              alt: null,
              title: null,
              width: 875,
              height: 2038,
            },
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/779b0f77-4b67-4047-833a-d4eb49796ddf/relationships/fch_vertical_image',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/779b0f77-4b67-4047-833a-d4eb49796ddf/fch_vertical_image',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/779b0f77-4b67-4047-833a-d4eb49796ddf',
        },
      },
    },
    // old?
    'ab975b14-a0e0-4512-9b70-11eca236de2f': {
      type: 'taxonomy_term--available_colors',
      id: 'ab975b14-a0e0-4512-9b70-11eca236de2f',
      attributes: {
        drupal_internal__tid: 14,
        drupal_internal__revision_id: 14,
        langcode: 'en',
        revision_created: '2019-10-15T09:52:14+00:00',
        revision_log_message: null,
        status: true,
        name: 'RAL 1011',
        description: null,
        weight: 0,
        changed: '2019-10-15T09:52:14+00:00',
        default_langcode: true,
        revision_translation_affected: true,
        path: {
          alias: null,
          pid: null,
          langcode: 'en',
        },
        fch_type: false,
      },
      relationships: {
        vid: {
          data: {
            type: 'taxonomy_vocabulary--taxonomy_vocabulary',
            id: '0e4eedd6-4f4f-429c-820b-23a0de1d404f',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/ab975b14-a0e0-4512-9b70-11eca236de2f/relationships/vid',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/ab975b14-a0e0-4512-9b70-11eca236de2f/vid',
            },
          },
        },
        revision_user: {
          data: null,
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/ab975b14-a0e0-4512-9b70-11eca236de2f/relationships/revision_user',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/ab975b14-a0e0-4512-9b70-11eca236de2f/revision_user',
            },
          },
        },
        parent: {
          data: [
            {
              type: 'taxonomy_term--available_colors',
              id: 'virtual',
              meta: {
                links: {
                  help: {
                    href: 'https://www.drupal.org/docs/8/modules/json-api/core-concepts#virtual',
                    meta: {
                      about: 'Usage and meaning of the \'virtual\' resource identifier.',
                    },
                  },
                },
              },
            },
          ],
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/ab975b14-a0e0-4512-9b70-11eca236de2f/relationships/parent',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/ab975b14-a0e0-4512-9b70-11eca236de2f/parent',
            },
          },
        },
        fch_horizontal_image: {
          data: null,
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/ab975b14-a0e0-4512-9b70-11eca236de2f/relationships/fch_horizontal_image',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/ab975b14-a0e0-4512-9b70-11eca236de2f/fch_horizontal_image',
            },
          },
        },
        fch_preview_image: {
          data: null,
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/ab975b14-a0e0-4512-9b70-11eca236de2f/relationships/fch_preview_image',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/ab975b14-a0e0-4512-9b70-11eca236de2f/fch_preview_image',
            },
          },
        },
        fch_vertical_image: {
          data: null,
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/ab975b14-a0e0-4512-9b70-11eca236de2f/relationships/fch_vertical_image',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/ab975b14-a0e0-4512-9b70-11eca236de2f/fch_vertical_image',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/taxonomy_term/available_colors/ab975b14-a0e0-4512-9b70-11eca236de2f',
        },
      },
    },
    'e8cba398-fee2-4c74-bbf4-6e5a10abe81f': {
      type: 'taxonomy_term--available_colors',
      id: 'e8cba398-fee2-4c74-bbf4-6e5a10abe81f',
      attributes: {
        drupal_internal__tid: 191,
        drupal_internal__revision_id: 191,
        langcode: 'en',
        revision_created: '2019-07-12T08:39:23+00:00',
        revision_log_message: null,
        status: true,
        name: 'RAL 9010',
        description: null,
        weight: 0,
        changed: '2019-07-12T08:39:23+00:00',
        default_langcode: true,
        revision_translation_affected: true,
        path: {
          alias: null,
          pid: null,
          langcode: 'en',
        },
        fch_type: false,
      },
      relationships: {
        vid: {
          data: {
            type: 'taxonomy_vocabulary--taxonomy_vocabulary',
            id: '0e4eedd6-4f4f-429c-820b-23a0de1d404f',
          },
          links: {
            self: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/taxonomy_term/available_colors/e8cba398-fee2-4c74-bbf4-6e5a10abe81f/relationships/vid',
            },
            related: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/taxonomy_term/available_colors/e8cba398-fee2-4c74-bbf4-6e5a10abe81f/vid',
            },
          },
        },
        revision_user: {
          data: null,
          links: {
            self: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/taxonomy_term/available_colors/e8cba398-fee2-4c74-bbf4-6e5a10abe81f/relationships/revision_user',
            },
            related: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/taxonomy_term/available_colors/e8cba398-fee2-4c74-bbf4-6e5a10abe81f/revision_user',
            },
          },
        },
        parent: {
          data: [
            {
              type: 'taxonomy_term--available_colors',
              id: 'virtual',
              meta: {
                links: {
                  help: {
                    href: 'https://www.drupal.org/docs/8/modules/json-api/core-concepts#virtual',
                    meta: {
                      about: "Usage and meaning of the 'virtual' resource identifier.",
                    },
                  },
                },
              },
            },
          ],
          links: {
            self: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/taxonomy_term/available_colors/e8cba398-fee2-4c74-bbf4-6e5a10abe81f/relationships/parent',
            },
            related: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/taxonomy_term/available_colors/e8cba398-fee2-4c74-bbf4-6e5a10abe81f/parent',
            },
          },
        },
        fch_attached_image: {
          data: null,
          links: {
            self: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/taxonomy_term/available_colors/e8cba398-fee2-4c74-bbf4-6e5a10abe81f/relationships/fch_attached_image',
            },
            related: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/taxonomy_term/available_colors/e8cba398-fee2-4c74-bbf4-6e5a10abe81f/fch_attached_image',
            },
          },
        },
      },
      links: {
        self: {
          href: 'https://configurator.backend.full.co.hu/jsonapi/taxonomy_term/available_colors/e8cba398-fee2-4c74-bbf4-6e5a10abe81f',
        },
      },
    },
  },
  doorAvailableColorsAttachedImage: {
    // Fenyő
    '62f5df85-2162-44e7-ba53-c3af4f9655c9': {
      type: 'file--image',
      id: '62f5df85-2162-44e7-ba53-c3af4f9655c9',
      attributes: {
        drupal_internal__fid: 67,
        langcode: 'en',
        filename: 'FeherAlandFenyo-H.jpg',
        uri: {
          value: 'public://photos/FeherAlandFenyo-H.jpg',
          url: '/sites/default/files/photos/FeherAlandFenyo-H.jpg',
        },
        filemime: 'image/jpeg',
        filesize: 1132661,
        status: true,
        created: '2019-11-06T14:31:59+00:00',
        changed: '2019-11-06T14:31:59+00:00',
        field_image_alt_text: null,
        field_image_title_text: null,
      },
      relationships: {
        file_type: {
          data: {
            type: 'file_type--file_type',
            id: '74105421-417e-4546-ba7f-8486208b1853',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/62f5df85-2162-44e7-ba53-c3af4f9655c9/relationships/file_type',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/62f5df85-2162-44e7-ba53-c3af4f9655c9/file_type',
            },
          },
        },
        uid: {
          data: null,
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/62f5df85-2162-44e7-ba53-c3af4f9655c9/relationships/uid',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/62f5df85-2162-44e7-ba53-c3af4f9655c9/uid',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/62f5df85-2162-44e7-ba53-c3af4f9655c9',
        },
      },
    },
    '52aa6ad0-975d-4654-bce1-5310bc18cded': {
      type: 'file--image',
      id: '52aa6ad0-975d-4654-bce1-5310bc18cded',
      attributes: {
        drupal_internal__fid: 30,
        langcode: 'en',
        filename: 'FeherAlandFenyo-V.jpg',
        uri: {
          value: 'public://photos/FeherAlandFenyo-V.jpg',
          url: '/sites/default/files/photos/FeherAlandFenyo-V.jpg',
        },
        filemime: 'image/jpeg',
        filesize: 1067653,
        status: true,
        created: '2019-11-06T14:31:59+00:00',
        changed: '2019-11-06T14:31:59+00:00',
        field_image_alt_text: null,
        field_image_title_text: null,
      },
      relationships: {
        file_type: {
          data: {
            type: 'file_type--file_type',
            id: '74105421-417e-4546-ba7f-8486208b1853',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/52aa6ad0-975d-4654-bce1-5310bc18cded/relationships/file_type',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/52aa6ad0-975d-4654-bce1-5310bc18cded/file_type',
            },
          },
        },
        uid: {
          data: null,
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/52aa6ad0-975d-4654-bce1-5310bc18cded/relationships/uid',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/52aa6ad0-975d-4654-bce1-5310bc18cded/uid',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/52aa6ad0-975d-4654-bce1-5310bc18cded',
        },
      },
    },
    // szilva
    '2184ebc8-2630-444f-b4f3-4bf6a77ddd73': {
      type: 'file--image',
      id: '2184ebc8-2630-444f-b4f3-4bf6a77ddd73',
      attributes: {
        drupal_internal__fid: 90,
        langcode: 'en',
        filename: 'VorosSzilva-H.jpg',
        uri: {
          value: 'public://photos/VorosSzilva-H.jpg',
          url: '/sites/default/files/photos/VorosSzilva-H.jpg',
        },
        filemime: 'image/jpeg',
        filesize: 2618948,
        status: true,
        created: '2019-11-06T14:31:59+00:00',
        changed: '2019-11-06T14:31:59+00:00',
        field_image_alt_text: null,
        field_image_title_text: null,
      },
      relationships: {
        file_type: {
          data: {
            type: 'file_type--file_type',
            id: '74105421-417e-4546-ba7f-8486208b1853',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/2184ebc8-2630-444f-b4f3-4bf6a77ddd73/relationships/file_type',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/2184ebc8-2630-444f-b4f3-4bf6a77ddd73/file_type',
            },
          },
        },
        uid: {
          data: null,
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/2184ebc8-2630-444f-b4f3-4bf6a77ddd73/relationships/uid',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/2184ebc8-2630-444f-b4f3-4bf6a77ddd73/uid',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/2184ebc8-2630-444f-b4f3-4bf6a77ddd73',
        },
      },
    },
    '1256f97e-2380-4684-850f-c3aefbf1342a': {
      type: 'file--image',
      id: '1256f97e-2380-4684-850f-c3aefbf1342a',
      attributes: {
        drupal_internal__fid: 54,
        langcode: 'en',
        filename: 'VorosSzilva-V.jpg',
        uri: {
          value: 'public://photos/VorosSzilva-V.jpg',
          url: '/sites/default/files/photos/VorosSzilva-V.jpg',
        },
        filemime: 'image/jpeg',
        filesize: 2548682,
        status: true,
        created: '2019-11-06T14:31:59+00:00',
        changed: '2019-11-06T14:31:59+00:00',
        field_image_alt_text: null,
        field_image_title_text: null,
      },
      relationships: {
        file_type: {
          data: {
            type: 'file_type--file_type',
            id: '74105421-417e-4546-ba7f-8486208b1853',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/1256f97e-2380-4684-850f-c3aefbf1342a/relationships/file_type',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/1256f97e-2380-4684-850f-c3aefbf1342a/file_type',
            },
          },
        },
        uid: {
          data: null,
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/1256f97e-2380-4684-850f-c3aefbf1342a/relationships/uid',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/1256f97e-2380-4684-850f-c3aefbf1342a/uid',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/1256f97e-2380-4684-850f-c3aefbf1342a',
        },
      },
    },
    // juhar
    'f260a371-f1db-4cc7-b3ec-181e4a78a58d': {
      type: 'file--image',
      id: 'f260a371-f1db-4cc7-b3ec-181e4a78a58d',
      attributes: {
        drupal_internal__fid: 77,
        langcode: 'en',
        filename: 'NaturJuhar-H.jpg',
        uri: {
          value: 'public://photos/NaturJuhar-H.jpg',
          url: '/sites/default/files/photos/NaturJuhar-H.jpg',
        },
        filemime: 'image/jpeg',
        filesize: 388549,
        status: true,
        created: '2019-11-06T14:31:59+00:00',
        changed: '2019-11-06T14:31:59+00:00',
        field_image_alt_text: null,
        field_image_title_text: null,
      },
      relationships: {
        file_type: {
          data: {
            type: 'file_type--file_type',
            id: '74105421-417e-4546-ba7f-8486208b1853',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/f260a371-f1db-4cc7-b3ec-181e4a78a58d/relationships/file_type',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/f260a371-f1db-4cc7-b3ec-181e4a78a58d/file_type',
            },
          },
        },
        uid: {
          data: null,
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/f260a371-f1db-4cc7-b3ec-181e4a78a58d/relationships/uid',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/f260a371-f1db-4cc7-b3ec-181e4a78a58d/uid',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/f260a371-f1db-4cc7-b3ec-181e4a78a58d',
        },
      },
    },
    '9f8c1202-39bc-4753-88dc-8f30c2022abc': {
      type: 'file--image',
      id: '9f8c1202-39bc-4753-88dc-8f30c2022abc',
      attributes: {
        drupal_internal__fid: 41,
        langcode: 'en',
        filename: 'NaturJuhar-V.jpg',
        uri: {
          value: 'public://photos/NaturJuhar-V.jpg',
          url: '/sites/default/files/photos/NaturJuhar-V.jpg',
        },
        filemime: 'image/jpeg',
        filesize: 381914,
        status: true,
        created: '2019-11-06T14:31:59+00:00',
        changed: '2019-11-06T14:31:59+00:00',
        field_image_alt_text: null,
        field_image_title_text: null,
      },
      relationships: {
        file_type: {
          data: {
            type: 'file_type--file_type',
            id: '74105421-417e-4546-ba7f-8486208b1853',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/9f8c1202-39bc-4753-88dc-8f30c2022abc/relationships/file_type',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/9f8c1202-39bc-4753-88dc-8f30c2022abc/file_type',
            },
          },
        },
        uid: {
          data: null,
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/9f8c1202-39bc-4753-88dc-8f30c2022abc/relationships/uid',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/9f8c1202-39bc-4753-88dc-8f30c2022abc/uid',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/9f8c1202-39bc-4753-88dc-8f30c2022abc',
        },
      },
    },
    // old
    'b563cfb3-d3c4-407a-8f43-4cebcf10862b': {
      type: 'file--image',
      id: 'b563cfb3-d3c4-407a-8f43-4cebcf10862b',
      attributes: {
        drupal_internal__fid: 25,
        langcode: 'en',
        filename: 'H3430.png',
        uri: {
          value: 'public://photos/H3430.png',
          url: '/sites/default/files/photos/H3430.png',
        },
        filemime: 'image/png',
        filesize: 357325,
        status: true,
        created: '2019-10-15T09:52:14+00:00',
        changed: '2019-10-15T09:52:14+00:00',
        field_image_alt_text: null,
        field_image_title_text: null,
      },
      relationships: {
        file_type: {
          data: {
            type: 'file_type--file_type',
            id: '74105421-417e-4546-ba7f-8486208b1853',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/b563cfb3-d3c4-407a-8f43-4cebcf10862b/relationships/file_type',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/b563cfb3-d3c4-407a-8f43-4cebcf10862b/file_type',
            },
          },
        },
        uid: {
          data: null,
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/b563cfb3-d3c4-407a-8f43-4cebcf10862b/relationships/uid',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/b563cfb3-d3c4-407a-8f43-4cebcf10862b/uid',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/b563cfb3-d3c4-407a-8f43-4cebcf10862b',
        },
      },
    },
    'c56381e2-810e-420a-8f45-8ece06cfa56e': {
      type: 'file--image',
      id: 'c56381e2-810e-420a-8f45-8ece06cfa56e',
      attributes: {
        drupal_internal__fid: 292,
        langcode: 'en',
        filename: 'H3430H.png',
        uri: {
          value: 'public://2019-10/H3430H.png',
          url: '/sites/default/files/2019-10/H3430H.png',
        },
        filemime: 'image/png',
        filesize: 360572,
        status: true,
        created: '2019-10-18T13:58:10+00:00',
        changed: '2019-10-18T13:58:35+00:00',
        field_image_alt_text: null,
        field_image_title_text: null,
      },
      relationships: {
        file_type: {
          data: {
            type: 'file_type--file_type',
            id: '74105421-417e-4546-ba7f-8486208b1853',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/c56381e2-810e-420a-8f45-8ece06cfa56e/relationships/file_type',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/c56381e2-810e-420a-8f45-8ece06cfa56e/file_type',
            },
          },
        },
        uid: {
          data: {
            type: 'user--user',
            id: '790cd157-c548-4cf4-ab44-ab19fff40dd9',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/c56381e2-810e-420a-8f45-8ece06cfa56e/relationships/uid',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/c56381e2-810e-420a-8f45-8ece06cfa56e/uid',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/c56381e2-810e-420a-8f45-8ece06cfa56e',
        },
      },
    },
    '200796d0-accf-4144-8dab-e2404015b7ac': {
      type: 'file--image',
      id: '200796d0-accf-4144-8dab-e2404015b7ac',
      attributes: {
        drupal_internal__fid: 293,
        langcode: 'en',
        filename: '8451-H.png',
        uri: {
          value: 'public://2019-10/8451-H.png',
          url: '/sites/default/files/2019-10/8451-H.png',
        },
        filemime: 'image/png',
        filesize: 329106,
        status: true,
        created: '2019-10-21T13:07:32+00:00',
        changed: '2019-10-21T13:07:42+00:00',
        field_image_alt_text: null,
        field_image_title_text: null,
      },
      relationships: {
        file_type: {
          data: {
            type: 'file_type--file_type',
            id: '74105421-417e-4546-ba7f-8486208b1853',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/200796d0-accf-4144-8dab-e2404015b7ac/relationships/file_type',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/200796d0-accf-4144-8dab-e2404015b7ac/file_type',
            },
          },
        },
        uid: {
          data: {
            type: 'user--user',
            id: '790cd157-c548-4cf4-ab44-ab19fff40dd9',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/200796d0-accf-4144-8dab-e2404015b7ac/relationships/uid',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/200796d0-accf-4144-8dab-e2404015b7ac/uid',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/200796d0-accf-4144-8dab-e2404015b7ac',
        },
      },
    },
    '25e29d37-ee96-4416-bb45-1c9df3edf16f': {
      type: 'file--image',
      id: '25e29d37-ee96-4416-bb45-1c9df3edf16f',
      attributes: {
        drupal_internal__fid: 35,
        langcode: 'en',
        filename: '8451.png',
        uri: {
          value: 'public://photos/8451.png',
          url: '/sites/default/files/photos/8451.png',
        },
        filemime: 'image/png',
        filesize: 310771,
        status: true,
        created: '2019-10-15T09:52:14+00:00',
        changed: '2019-10-15T09:52:14+00:00',
        field_image_alt_text: null,
        field_image_title_text: null,
      },
      relationships: {
        file_type: {
          data: {
            type: 'file_type--file_type',
            id: '74105421-417e-4546-ba7f-8486208b1853',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/25e29d37-ee96-4416-bb45-1c9df3edf16f/relationships/file_type',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/25e29d37-ee96-4416-bb45-1c9df3edf16f/file_type',
            },
          },
        },
        uid: {
          data: null,
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/25e29d37-ee96-4416-bb45-1c9df3edf16f/relationships/uid',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/25e29d37-ee96-4416-bb45-1c9df3edf16f/uid',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/25e29d37-ee96-4416-bb45-1c9df3edf16f',
        },
      },
    },
    '659bcf4a-40c7-4f2b-b616-5159a5235274': {
      type: 'file--image',
      id: '659bcf4a-40c7-4f2b-b616-5159a5235274',
      attributes: {
        drupal_internal__fid: 55,
        langcode: 'en',
        filename: 'juhar.png',
        uri: {
          value: 'public://photos/juhar.png',
          url: '/sites/default/files/photos/juhar.png',
        },
        filemime: 'image/png',
        filesize: 278346,
        status: true,
        created: '2019-10-15T09:52:14+00:00',
        changed: '2019-10-15T09:52:14+00:00',
        field_image_alt_text: null,
        field_image_title_text: null,
      },
      relationships: {
        file_type: {
          data: {
            type: 'file_type--file_type',
            id: '74105421-417e-4546-ba7f-8486208b1853',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/659bcf4a-40c7-4f2b-b616-5159a5235274/relationships/file_type',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/659bcf4a-40c7-4f2b-b616-5159a5235274/file_type',
            },
          },
        },
        uid: {
          data: null,
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/659bcf4a-40c7-4f2b-b616-5159a5235274/relationships/uid',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/659bcf4a-40c7-4f2b-b616-5159a5235274/uid',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/659bcf4a-40c7-4f2b-b616-5159a5235274',
        },
      },
    },
    'cc03968b-d85f-4992-ab9a-a90ed351c65e': {
      type: 'file--image',
      id: 'cc03968b-d85f-4992-ab9a-a90ed351c65e',
      attributes: {
        drupal_internal__fid: 294,
        langcode: 'en',
        filename: 'juhar-H.png',
        uri: {
          value: 'public://2019-10/juhar-H.png',
          url: '/sites/default/files/2019-10/juhar-H.png',
        },
        filemime: 'image/png',
        filesize: 286408,
        status: true,
        created: '2019-10-21T13:43:25+00:00',
        changed: '2019-10-21T13:43:29+00:00',
        field_image_alt_text: null,
        field_image_title_text: null,
      },
      relationships: {
        file_type: {
          data: {
            type: 'file_type--file_type',
            id: '74105421-417e-4546-ba7f-8486208b1853',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/cc03968b-d85f-4992-ab9a-a90ed351c65e/relationships/file_type',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/cc03968b-d85f-4992-ab9a-a90ed351c65e/file_type',
            },
          },
        },
        uid: {
          data: {
            type: 'user--user',
            id: '790cd157-c548-4cf4-ab44-ab19fff40dd9',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/cc03968b-d85f-4992-ab9a-a90ed351c65e/relationships/uid',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/cc03968b-d85f-4992-ab9a-a90ed351c65e/uid',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/cc03968b-d85f-4992-ab9a-a90ed351c65e',
        },
      },
    },
  },
  doorWingType: {},
  doorOpeningDirection: {},
  doorDesign: {},
  doorDesignExtra: {},
  doorDimensions,
  doorGlassPattern: {
    '9cccb1f4-86b8-4b31-9ff2-47d23b0539a4': {
      type: 'node--door_glass_pattern',
      id: '9cccb1f4-86b8-4b31-9ff2-47d23b0539a4',
      attributes: {
        drupal_internal__nid: 45,
        drupal_internal__vid: 45,
        langcode: 'en',
        revision_timestamp: '2019-11-06T14:31:59+00:00',
        revision_log: null,
        status: true,
        title: 'Savmart',
        created: '2019-11-06T14:31:59+00:00',
        changed: '2019-11-06T14:31:59+00:00',
        promote: true,
        sticky: false,
        default_langcode: true,
        revision_translation_affected: true,
        path: {
          alias: null,
          pid: null,
          langcode: 'en',
        },
      },
      relationships: {
        node_type: {
          data: {
            type: 'node_type--node_type',
            id: '05c2c81f-d7e3-4dcd-b675-a0dbb61e1db4',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_glass_pattern/9cccb1f4-86b8-4b31-9ff2-47d23b0539a4/relationships/node_type?resourceVersion=id%3A45',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_glass_pattern/9cccb1f4-86b8-4b31-9ff2-47d23b0539a4/node_type?resourceVersion=id%3A45',
            },
          },
        },
        revision_uid: {
          data: {
            type: 'user--user',
            id: '7ebb9fa8-f81e-489c-8afc-17f6c9520c3e',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_glass_pattern/9cccb1f4-86b8-4b31-9ff2-47d23b0539a4/relationships/revision_uid?resourceVersion=id%3A45',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_glass_pattern/9cccb1f4-86b8-4b31-9ff2-47d23b0539a4/revision_uid?resourceVersion=id%3A45',
            },
          },
        },
        uid: {
          data: {
            type: 'user--user',
            id: '7ebb9fa8-f81e-489c-8afc-17f6c9520c3e',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_glass_pattern/9cccb1f4-86b8-4b31-9ff2-47d23b0539a4/relationships/uid?resourceVersion=id%3A45',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_glass_pattern/9cccb1f4-86b8-4b31-9ff2-47d23b0539a4/uid?resourceVersion=id%3A45',
            },
          },
        },
        fch_attached_image: {
          data: {
            type: 'file--image',
            id: '0a704f58-0daf-48c3-9412-2d140c91a0b8',
            meta: {
              alt: null,
              title: null,
              width: 1033,
              height: 1473,
            },
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_glass_pattern/9cccb1f4-86b8-4b31-9ff2-47d23b0539a4/relationships/fch_attached_image?resourceVersion=id%3A45',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_glass_pattern/9cccb1f4-86b8-4b31-9ff2-47d23b0539a4/fch_attached_image?resourceVersion=id%3A45',
            },
          },
        },
        fch_preview_image: {
          data: {
            type: 'file--image',
            id: '8f1338ce-26b0-4816-97c5-451d5148d9ae',
            meta: {
              alt: null,
              title: null,
              width: 120,
              height: 120,
            },
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_glass_pattern/9cccb1f4-86b8-4b31-9ff2-47d23b0539a4/relationships/fch_preview_image?resourceVersion=id%3A45',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_glass_pattern/9cccb1f4-86b8-4b31-9ff2-47d23b0539a4/fch_preview_image?resourceVersion=id%3A45',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_glass_pattern/9cccb1f4-86b8-4b31-9ff2-47d23b0539a4?resourceVersion=id%3A45',
        },
      },
    },
  },
  doorGlassPatternAttachedImage: {
    '0a704f58-0daf-48c3-9412-2d140c91a0b8': {
      type: 'file--image',
      id: '0a704f58-0daf-48c3-9412-2d140c91a0b8',
      attributes: {
        drupal_internal__fid: 138,
        langcode: 'en',
        filename: 'savmart.png',
        uri: {
          value: 'public://photos/savmart.png',
          url: '/sites/default/files/photos/savmart.png',
        },
        filemime: 'image/png',
        filesize: 587372,
        status: true,
        created: '2019-11-06T14:31:59+00:00',
        changed: '2019-11-06T14:31:59+00:00',
        field_image_alt_text: null,
        field_image_title_text: null,
      },
      relationships: {
        file_type: {
          data: {
            type: 'file_type--file_type',
            id: '74105421-417e-4546-ba7f-8486208b1853',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/0a704f58-0daf-48c3-9412-2d140c91a0b8/relationships/file_type',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/0a704f58-0daf-48c3-9412-2d140c91a0b8/file_type',
            },
          },
        },
        uid: {
          data: null,
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/0a704f58-0daf-48c3-9412-2d140c91a0b8/relationships/uid',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/0a704f58-0daf-48c3-9412-2d140c91a0b8/uid',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/0a704f58-0daf-48c3-9412-2d140c91a0b8',
        },
      },
    },
    '8f1338ce-26b0-4816-97c5-451d5148d9ae': {
      type: 'file--image',
      id: '8f1338ce-26b0-4816-97c5-451d5148d9ae',
      attributes: {
        drupal_internal__fid: 150,
        langcode: 'en',
        filename: 'savmart.jpg',
        uri: {
          value: 'public://photos/savmart.jpg',
          url: '/sites/default/files/photos/savmart.jpg',
        },
        filemime: 'image/jpeg',
        filesize: 7416,
        status: true,
        created: '2019-11-06T14:31:59+00:00',
        changed: '2019-11-06T14:31:59+00:00',
        field_image_alt_text: null,
        field_image_title_text: null,
      },
      relationships: {
        file_type: {
          data: {
            type: 'file_type--file_type',
            id: '74105421-417e-4546-ba7f-8486208b1853',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/8f1338ce-26b0-4816-97c5-451d5148d9ae/relationships/file_type',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/8f1338ce-26b0-4816-97c5-451d5148d9ae/file_type',
            },
          },
        },
        uid: {
          data: null,
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/8f1338ce-26b0-4816-97c5-451d5148d9ae/relationships/uid',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/8f1338ce-26b0-4816-97c5-451d5148d9ae/uid',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/8f1338ce-26b0-4816-97c5-451d5148d9ae',
        },
      },
    },
  },
  doorGlazingType: {},
  doorGlazing: {},
  doorGlazingAttachedImage: {},
  doorPattern: {},
  doorPatternInstance: {
    '9840cc76-0629-4264-8ece-aa919ba5f9ec': {
      type: 'node--door_pattern_instance',
      id: '9840cc76-0629-4264-8ece-aa919ba5f9ec',
      attributes: {
        drupal_internal__nid: 159,
        drupal_internal__vid: 452,
        langcode: 'en',
        revision_timestamp: '2019-09-05T12:15:15+00:00',
        revision_log: null,
        status: true,
        title: 'Solid V',
        created: '2019-07-12T08:39:23+00:00',
        changed: '2019-09-05T12:15:15+00:00',
        promote: true,
        sticky: false,
        default_langcode: true,
        revision_translation_affected: true,
        path: {
          alias: null,
          pid: null,
          langcode: 'en',
        },
        fch_min_height: 400,
        fch_min_width: 200,
        fch_pattern_direction: 'V',
        fch_shapes: '[]',
      },
      relationships: {
        node_type: {
          data: {
            type: 'node_type--node_type',
            id: 'b9851fc4-9452-4e58-a4c1-9f5e9c81ca3b',
          },
          links: {
            self: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_pattern_instance/9840cc76-0629-4264-8ece-aa919ba5f9ec/relationships/node_type?resourceVersion=id%3A452',
            },
            related: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_pattern_instance/9840cc76-0629-4264-8ece-aa919ba5f9ec/node_type?resourceVersion=id%3A452',
            },
          },
        },
        revision_uid: {
          data: {
            type: 'user--user',
            id: 'f7e03635-2506-48ad-8e96-4adfc953e08d',
          },
          links: {
            self: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_pattern_instance/9840cc76-0629-4264-8ece-aa919ba5f9ec/relationships/revision_uid?resourceVersion=id%3A452',
            },
            related: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_pattern_instance/9840cc76-0629-4264-8ece-aa919ba5f9ec/revision_uid?resourceVersion=id%3A452',
            },
          },
        },
        uid: {
          data: {
            type: 'user--user',
            id: 'f7e03635-2506-48ad-8e96-4adfc953e08d',
          },
          links: {
            self: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_pattern_instance/9840cc76-0629-4264-8ece-aa919ba5f9ec/relationships/uid?resourceVersion=id%3A452',
            },
            related: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_pattern_instance/9840cc76-0629-4264-8ece-aa919ba5f9ec/uid?resourceVersion=id%3A452',
            },
          },
        },
        fch_attached_image: {
          data: {
            type: 'file--image',
            id: 'c12b19a6-9267-478d-a5e4-4966a73ed4b7',
            meta: {
              alt: '',
              title: '',
              width: 1027,
              height: 2456,
            },
          },
          links: {
            self: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_pattern_instance/9840cc76-0629-4264-8ece-aa919ba5f9ec/relationships/fch_attached_image?resourceVersion=id%3A452',
            },
            related: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_pattern_instance/9840cc76-0629-4264-8ece-aa919ba5f9ec/fch_attached_image?resourceVersion=id%3A452',
            },
          },
        },
        fch_door_pattern: {
          data: [
            {
              type: 'node--door_pattern',
              id: 'f8ce5cbd-a10c-4b51-9bce-e69268d88cde',
            },
          ],
          links: {
            self: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_pattern_instance/9840cc76-0629-4264-8ece-aa919ba5f9ec/relationships/fch_door_pattern?resourceVersion=id%3A452',
            },
            related: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_pattern_instance/9840cc76-0629-4264-8ece-aa919ba5f9ec/fch_door_pattern?resourceVersion=id%3A452',
            },
          },
        },
      },
      links: {
        self: {
          href: 'https://configurator.backend.full.co.hu/jsonapi/node/door_pattern_instance/9840cc76-0629-4264-8ece-aa919ba5f9ec?resourceVersion=id%3A452',
        },
      },
    },
  },
  doorPatternInstanceAttachedImage: {
    'c12b19a6-9267-478d-a5e4-4966a73ed4b7': {
      type: 'file--image',
      id: 'c12b19a6-9267-478d-a5e4-4966a73ed4b7',
      attributes: {
        drupal_internal__fid: 184,
        langcode: 'en',
        filename: 'Solid V.png',
        uri: {
          value: 'public://photos/Solid%20V.png',
          url: '/sites/default/files/photos/Solid%2520V.png',
        },
        filemime: 'image/png',
        filesize: 51878,
        status: true,
        created: '2019-07-12T08:39:23+00:00',
        changed: '2019-07-12T08:39:23+00:00',
        field_image_alt_text: null,
        field_image_title_text: null,
      },
      relationships: {
        file_type: {
          data: {
            type: 'file_type--file_type',
            id: '74105421-417e-4546-ba7f-8486208b1853',
          },
          links: {
            self: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/file/image/c12b19a6-9267-478d-a5e4-4966a73ed4b7/relationships/file_type',
            },
            related: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/file/image/c12b19a6-9267-478d-a5e4-4966a73ed4b7/file_type',
            },
          },
        },
        uid: {
          data: null,
          links: {
            self: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/file/image/c12b19a6-9267-478d-a5e4-4966a73ed4b7/relationships/uid',
            },
            related: {
              href: 'https://configurator.backend.full.co.hu/jsonapi/file/image/c12b19a6-9267-478d-a5e4-4966a73ed4b7/uid',
            },
          },
        },
      },
      links: {
        self: {
          href: 'https://configurator.backend.full.co.hu/jsonapi/file/image/c12b19a6-9267-478d-a5e4-4966a73ed4b7',
        },
      },
    },
  },
  doorAccessory: {
    '6b55dffa-98fc-4bb8-bcf2-62b7f5a4bdb0': {
      type: 'node--door_accessories',
      id: '6b55dffa-98fc-4bb8-bcf2-62b7f5a4bdb0',
      attributes: {
        drupal_internal__nid: 1,
        drupal_internal__vid: 1,
        langcode: 'en',
        revision_timestamp: '2019-10-15T09:52:14+00:00',
        revision_log: null,
        status: true,
        title: 'Thema Pro (kör)',
        created: '2019-10-15T09:52:14+00:00',
        changed: '2019-10-15T09:52:14+00:00',
        promote: true,
        sticky: false,
        default_langcode: true,
        revision_translation_affected: true,
        path: {
          alias: null,
          pid: null,
          langcode: 'en',
        },
      },
      relationships: {
        node_type: {
          data: {
            type: 'node_type--node_type',
            id: '4688dc27-9556-45fb-b85e-15502f82bf91',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/6b55dffa-98fc-4bb8-bcf2-62b7f5a4bdb0/relationships/node_type?resourceVersion=id%3A1',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/6b55dffa-98fc-4bb8-bcf2-62b7f5a4bdb0/node_type?resourceVersion=id%3A1',
            },
          },
        },
        revision_uid: {
          data: {
            type: 'user--user',
            id: '790cd157-c548-4cf4-ab44-ab19fff40dd9',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/6b55dffa-98fc-4bb8-bcf2-62b7f5a4bdb0/relationships/revision_uid?resourceVersion=id%3A1',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/6b55dffa-98fc-4bb8-bcf2-62b7f5a4bdb0/revision_uid?resourceVersion=id%3A1',
            },
          },
        },
        uid: {
          data: {
            type: 'user--user',
            id: '790cd157-c548-4cf4-ab44-ab19fff40dd9',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/6b55dffa-98fc-4bb8-bcf2-62b7f5a4bdb0/relationships/uid?resourceVersion=id%3A1',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/6b55dffa-98fc-4bb8-bcf2-62b7f5a4bdb0/uid?resourceVersion=id%3A1',
            },
          },
        },
        fch_attached_image: {
          data: {
            type: 'file--image',
            id: '691d0f7e-8964-4461-aabb-e870954b74a5',
            meta: {
              alt: null,
              title: null,
              width: 206,
              height: 206,
            },
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/6b55dffa-98fc-4bb8-bcf2-62b7f5a4bdb0/relationships/fch_attached_image?resourceVersion=id%3A1',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/6b55dffa-98fc-4bb8-bcf2-62b7f5a4bdb0/fch_attached_image?resourceVersion=id%3A1',
            },
          },
        },
        fch_door_accessories_type: {
          data: {
            type: 'taxonomy_term--door_accessories_type',
            id: 'a9987f34-c654-47ba-aa16-1c401cf2e15c',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/6b55dffa-98fc-4bb8-bcf2-62b7f5a4bdb0/relationships/fch_door_accessories_type?resourceVersion=id%3A1',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/6b55dffa-98fc-4bb8-bcf2-62b7f5a4bdb0/fch_door_accessories_type?resourceVersion=id%3A1',
            },
          },
        },
        fch_door_opening_type: {
          data: {
            type: 'taxonomy_term--door_opening_type',
            id: '1dc317f1-5c88-480a-8d5e-4d8dd22eb927',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/6b55dffa-98fc-4bb8-bcf2-62b7f5a4bdb0/relationships/fch_door_opening_type?resourceVersion=id%3A1',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/6b55dffa-98fc-4bb8-bcf2-62b7f5a4bdb0/fch_door_opening_type?resourceVersion=id%3A1',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/6b55dffa-98fc-4bb8-bcf2-62b7f5a4bdb0?resourceVersion=id%3A1',
        },
      },
    },
    'c9a07c9d-abdf-491b-84d6-9dca46c3884d': {
      type: 'node--door_accessories',
      id: 'c9a07c9d-abdf-491b-84d6-9dca46c3884d',
      attributes: {
        drupal_internal__nid: 13,
        drupal_internal__vid: 13,
        langcode: 'en',
        revision_timestamp: '2019-10-15T09:52:14+00:00',
        revision_log: null,
        status: true,
        title: 'Kör (nagy)',
        created: '2019-10-15T09:52:14+00:00',
        changed: '2019-10-15T09:52:14+00:00',
        promote: true,
        sticky: false,
        default_langcode: true,
        revision_translation_affected: true,
        path: {
          alias: null,
          pid: null,
          langcode: 'en',
        },
      },
      relationships: {
        node_type: {
          data: {
            type: 'node_type--node_type',
            id: '4688dc27-9556-45fb-b85e-15502f82bf91',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/c9a07c9d-abdf-491b-84d6-9dca46c3884d/relationships/node_type?resourceVersion=id%3A13',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/c9a07c9d-abdf-491b-84d6-9dca46c3884d/node_type?resourceVersion=id%3A13',
            },
          },
        },
        revision_uid: {
          data: {
            type: 'user--user',
            id: '790cd157-c548-4cf4-ab44-ab19fff40dd9',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/c9a07c9d-abdf-491b-84d6-9dca46c3884d/relationships/revision_uid?resourceVersion=id%3A13',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/c9a07c9d-abdf-491b-84d6-9dca46c3884d/revision_uid?resourceVersion=id%3A13',
            },
          },
        },
        uid: {
          data: {
            type: 'user--user',
            id: '790cd157-c548-4cf4-ab44-ab19fff40dd9',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/c9a07c9d-abdf-491b-84d6-9dca46c3884d/relationships/uid?resourceVersion=id%3A13',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/c9a07c9d-abdf-491b-84d6-9dca46c3884d/uid?resourceVersion=id%3A13',
            },
          },
        },
        fch_attached_image: {
          data: {
            type: 'file--image',
            id: 'a7a73dd5-9510-429b-b1d5-e616eaf4a2e6',
            meta: {
              alt: null,
              title: null,
              width: 206,
              height: 206,
            },
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/c9a07c9d-abdf-491b-84d6-9dca46c3884d/relationships/fch_attached_image?resourceVersion=id%3A13',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/c9a07c9d-abdf-491b-84d6-9dca46c3884d/fch_attached_image?resourceVersion=id%3A13',
            },
          },
        },
        fch_door_accessories_type: {
          data: {
            type: 'taxonomy_term--door_accessories_type',
            id: 'b99ac9b6-5f61-4c04-9d39-70691a3b530d',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/c9a07c9d-abdf-491b-84d6-9dca46c3884d/relationships/fch_door_accessories_type?resourceVersion=id%3A13',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/c9a07c9d-abdf-491b-84d6-9dca46c3884d/fch_door_accessories_type?resourceVersion=id%3A13',
            },
          },
        },
        fch_door_opening_type: {
          data: {
            type: 'taxonomy_term--door_opening_type',
            id: '5e405193-2dcc-445e-969a-246ca5d7358b',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/c9a07c9d-abdf-491b-84d6-9dca46c3884d/relationships/fch_door_opening_type?resourceVersion=id%3A13',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/c9a07c9d-abdf-491b-84d6-9dca46c3884d/fch_door_opening_type?resourceVersion=id%3A13',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/node/door_accessories/c9a07c9d-abdf-491b-84d6-9dca46c3884d?resourceVersion=id%3A13',
        },
      },
    },
  },
  doorAccessoryAttachedImage: {
    '691d0f7e-8964-4461-aabb-e870954b74a5': {
      type: 'file--image',
      id: '691d0f7e-8964-4461-aabb-e870954b74a5',
      attributes: {
        drupal_internal__fid: 4,
        langcode: 'en',
        filename: 'Thema_PZ.png',
        uri: {
          value: 'public://photos/Thema_PZ.png',
          url: '/sites/default/files/photos/Thema_PZ.png',
        },
        filemime: 'image/png',
        filesize: 170108,
        status: true,
        created: '2019-10-15T09:52:14+00:00',
        changed: '2019-10-15T09:52:14+00:00',
        field_image_alt_text: null,
        field_image_title_text: null,
      },
      relationships: {
        file_type: {
          data: {
            type: 'file_type--file_type',
            id: '74105421-417e-4546-ba7f-8486208b1853',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/691d0f7e-8964-4461-aabb-e870954b74a5/relationships/file_type',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/691d0f7e-8964-4461-aabb-e870954b74a5/file_type',
            },
          },
        },
        uid: {
          data: null,
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/691d0f7e-8964-4461-aabb-e870954b74a5/relationships/uid',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/691d0f7e-8964-4461-aabb-e870954b74a5/uid',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/691d0f7e-8964-4461-aabb-e870954b74a5',
        },
      },
    },
    'a7a73dd5-9510-429b-b1d5-e616eaf4a2e6': {
      type: 'file--image',
      id: 'a7a73dd5-9510-429b-b1d5-e616eaf4a2e6',
      attributes: {
        drupal_internal__fid: 16,
        langcode: 'en',
        filename: 'Kor_nagy.png',
        uri: {
          value: 'public://photos/Kor_nagy.png',
          url: '/sites/default/files/photos/Kor_nagy.png',
        },
        filemime: 'image/png',
        filesize: 66164,
        status: true,
        created: '2019-10-15T09:52:14+00:00',
        changed: '2019-10-15T09:52:14+00:00',
        field_image_alt_text: null,
        field_image_title_text: null,
      },
      relationships: {
        file_type: {
          data: {
            type: 'file_type--file_type',
            id: '74105421-417e-4546-ba7f-8486208b1853',
          },
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/a7a73dd5-9510-429b-b1d5-e616eaf4a2e6/relationships/file_type',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/a7a73dd5-9510-429b-b1d5-e616eaf4a2e6/file_type',
            },
          },
        },
        uid: {
          data: null,
          links: {
            self: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/a7a73dd5-9510-429b-b1d5-e616eaf4a2e6/relationships/uid',
            },
            related: {
              href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/a7a73dd5-9510-429b-b1d5-e616eaf4a2e6/uid',
            },
          },
        },
      },
      links: {
        self: {
          href: 'http://master-fulldoors-configurator-rework.s.cheppersdev.com/jsonapi/file/image/a7a73dd5-9510-429b-b1d5-e616eaf4a2e6',
        },
      },
    },
  },
};

export default initialState;
