import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Timeline } from 'antd';
import { withTranslation } from 'react-i18next';
import { selectStep } from 'actions/canvasActions';
import { StepperText } from './StepperText';
import { StepperRalColor } from './StepperRalColor';
import './stepper.scss';

class Stepper extends React.Component {
  getStepClassName = (index) => {
    let stepClassName = '';
    const { configurator: { currentStep, maxStep } } = this.props;

    if (index === currentStep) {
      stepClassName += ' current-step';
    } else if (index <= maxStep) {
      stepClassName += ' passed-step';
    }

    return stepClassName;
  }

  getSelectedItem = (storage, item) => {
    const { availableOptions, selectedOptions } = this.props;
    try {
      return availableOptions[storage][selectedOptions[item]].attributes.title || availableOptions[storage][selectedOptions[item]].attributes.name;
    } catch (error) {
      return null;
    }
  }

  getSelectedInstance = (instanceClass, instance) => {
    const { configurator } = this.props;
    const instances = (configurator.instances[configurator[instanceClass]]);
    if (instances === undefined) {
      return null;
    }
    const selectedInstance = instances[configurator[instance]];
    if (selectedInstance) {
      return selectedInstance.title;
    }
    return null;
  }

  getDoorSizes = () => {
    const {
      configurator: {
        selectedDoorDesignExtra,
        doorActiveWallOpeningWidth,
        doorWallOpeningHeight,
        doorBlendeHeight,
        doorActiveWallOpeningWidthExtra,
        selectedDoorWingType,
      },
    } = this.props;

    const wings = selectedDoorWingType === 'e668fcdb-b4b5-4857-b106-6d500275d6a7' ? 1 : 2;
    let { configurator: { doorPassiveWallOpeningWidth } } = this.props;
    if (wings === 1) {
      doorPassiveWallOpeningWidth = 0;
    }

    switch (selectedDoorDesignExtra) {
      // Alap
      case '2748d41f-7591-4dc3-9719-643a907fd04b': {
        const width = doorActiveWallOpeningWidth + doorPassiveWallOpeningWidth;
        const height = doorWallOpeningHeight;
        return `${width} x ${height} mm`;
      }

      // Fix blende
      case '14b18b80-1e0d-43a2-8c33-54d70adecc74': {
        const width = doorActiveWallOpeningWidth + doorPassiveWallOpeningWidth;
        const height = doorWallOpeningHeight + doorBlendeHeight;
        return `${width} x ${height} mm`;
      }

      // Osztott blende
      case '0f676369-6af5-4195-9e03-eff49e7d29d0': {
        const width = doorActiveWallOpeningWidth + doorPassiveWallOpeningWidth;
        const height = doorWallOpeningHeight + doorBlendeHeight;
        return `${width} x ${height} mm`;
      }

      // Sorolt oldalvilágító
      case '98b3d677-7bd9-4d2f-b145-b07e69815cc3': {
        const width = doorActiveWallOpeningWidth + doorPassiveWallOpeningWidth + doorActiveWallOpeningWidthExtra;
        const height = doorWallOpeningHeight;
        return `${width} x ${height} mm`;
      }

      // Sorolt felülvilágító
      case '5f2f79b6-412c-46bb-befe-cc9cc1624445': {
        const width = doorActiveWallOpeningWidth + doorPassiveWallOpeningWidth;
        const height = doorWallOpeningHeight + doorBlendeHeight;
        return `${width} x ${height} mm`;
      }

      // Tokosztós felülvilágító
      case '100539b5-679e-418a-a470-e3be9e65bc89': {
        const width = doorActiveWallOpeningWidth + doorPassiveWallOpeningWidth;
        const height = doorWallOpeningHeight + doorBlendeHeight;
        return `${width} x ${height} mm`;
      }

      default:
        return '';
    }
  }

  handleJumpToStep = (e) => {
    const { dispatchSelectStep, configurator: { maxStep } } = this.props;
    const index = parseInt(e.target.dataset.stepId, 10);
    if (index <= maxStep) {
      dispatchSelectStep(index);
    }
  }

  renderTimelineItem = (step, index) => {
    const stepClassName = this.getStepClassName(index + 1);

    return (
      <Timeline.Item key={index} className={stepClassName}>
        <div className="stepper-content-title">{`0${index} ${step.title}`}</div>
        {step.body}
      </Timeline.Item>
    );
  }

  render() {
    const {
      t, selectedOptions: {
        selectedDoorWingType,
        selectedDoorDesignExtra,
        selectedDoorGlazingPassive,
        selectedDoorInlay1ColorActive,
        selectedDoorInlay2ColorActive,
        selectedDoorInlay1ColorPassive,
        selectedDoorInlay2ColorPassive,
      },
    } = this.props;
    const { collapsed } = this.props;
    const wallColor = 'RAL 9010';
    const floorColor = 'RAL 1011';
    const stepperClass = collapsed ? ' collapsed' : '';
    const wings = selectedDoorWingType === 'e668fcdb-b4b5-4857-b106-6d500275d6a7' ? 1 : 2;

    return (
      <Timeline className={`stepper${stepperClass}`}>
        <Timeline.Item className={this.getStepClassName(1)}>
          <button
            type="button"
            className="stepper-content-title"
            data-step-id={1}
            onClick={this.handleJumpToStep}
          >
            <span>01 </span>
            {t('stepper.step_1.title')}
          </button>
          <StepperText
            title={t('stepper.step_1.children.style')}
            contentText={this.getSelectedItem('doorFamily', 'selectedDoorFamily')}
          />
          <StepperText
            title={t('stepper.step_1.children.context')}
            contentText={this.getSelectedItem('doorAvailableLocation', 'selectedLocation')}
          />
          <StepperRalColor
            title={t('stepper.step_1.children.wall')}
            ralColor={wallColor}
            isFilled
          />
          <StepperRalColor
            title={t('stepper.step_1.children.floor')}
            ralColor={floorColor}
            isFilled
          />
        </Timeline.Item>

        <Timeline.Item className={this.getStepClassName(2)}>
          <button
            type="button"
            className="stepper-content-title"
            data-step-id={2}
            onClick={this.handleJumpToStep}
          >
            <span>02 </span>
            {t('stepper.step_2.title')}
          </button>
          <StepperText
            title={t('stepper.step_2.page_buttons_label.choose_wing')}
            contentText={this.getSelectedItem('doorWingType', 'selectedDoorWingType')}
          />

          <StepperText
            title={t('stepper.step_2.children.door_type')}
            contentText={this.getSelectedItem('doorDesign', 'selectedDoorDesign')}
          />
          <StepperText
            title={t('stepper.step_2.children.extra')}
            contentText={this.getSelectedItem('doorDesignExtra', 'selectedDoorDesignExtra')}
          />
          <StepperText
            title={t('stepper.step_2.children.size')}
            contentText={this.getDoorSizes()}
            isFilled
          />
          <StepperText
            title={t('stepper.step_2.children.dimension')}
            contentText={this.getSelectedItem('doorOpeningDirection', 'selectedDoorOpeningDirection')}
          />
        </Timeline.Item>

        <Timeline.Item className={this.getStepClassName(3)}>
          <button
            type="button"
            className="stepper-content-title"
            data-step-id={3}
            onClick={this.handleJumpToStep}
          >
            <span>03 </span>
            {t('stepper.step_3.title')}
          </button>
          {wings === 1
            ? [
              <StepperText
                key="selectedActiveInstanceClass"
                title={t('stepper.step_3.children.model')}
                contentText={this.getSelectedItem('doorPattern', 'selectedDoorPatternActive')}
              />,
              <StepperText
                key="selectedActiveInstance"
                title={t('stepper.step_3.children.layout')}
                contentText={this.getSelectedItem('doorPatternInstance', 'selectedDoorPatternInstanceActive')}
              />,
            ]
            : [
              <StepperText
                key="selectedActiveInstanceClass"
                title={t('stepper.step_3.children.active_model')}
                contentText={this.getSelectedItem('doorPattern', 'selectedDoorPatternActive')}
              />,
              <StepperText
                key="selectedActiveInstance"
                title={t('stepper.step_3.children.active_layout')}
                contentText={this.getSelectedItem('doorPatternInstance', 'selectedDoorPatternInstanceActive')}
              />,
              <StepperText
                key="selectedPassiveInstanceClass"
                title={t('stepper.step_3.children.passive_model')}
                contentText={this.getSelectedItem('doorPattern', 'selectedDoorPatternPassive')}
              />,
              <StepperText
                key="selectedPassiveInstance"
                title={t('stepper.step_3.children.passive_layout')}
                contentText={this.getSelectedItem('doorPatternInstance', 'selectedDoorPatternInstancePassive')}
              />,
            ]}
        </Timeline.Item>

        <Timeline.Item className={this.getStepClassName(4)}>
          <button
            type="button"
            className="stepper-content-title"
            data-step-id={4}
            onClick={this.handleJumpToStep}
          >
            <span>04 </span>
            {t('stepper.step_4.title')}
          </button>
          {
            (
              selectedDoorDesignExtra === '0f676369-6af5-4195-9e03-eff49e7d29d0'
              || selectedDoorDesignExtra === '98b3d677-7bd9-4d2f-b145-b07e69815cc3'
              || selectedDoorDesignExtra === '5f2f79b6-412c-46bb-befe-cc9cc1624445'
              || selectedDoorDesignExtra === '100539b5-679e-418a-a470-e3be9e65bc89'
            )
            && (
              <StepperText
                title={t('stepper.step_4.children.base_glass')}
                contentText={this.getSelectedItem('doorGlassPattern', 'selectedWindowGlassPattern')}
              />
            )
          }
          <StepperText
            key="glass_pattern"
            title={t('stepper.step_4.children.active_glass_pattern')}
            contentText={this.getSelectedItem('doorGlassPattern', 'selectedDoorGlassPatternActive')}
          />
          <StepperText
            key="glass_family"
            title={t('stepper.step_4.children.active_glass_family')}
            contentText={this.getSelectedItem('doorGlazingType', 'selectedDoorGlazingTypeActive')}
          />
          <StepperText
            key="glass_type"
            title={t('stepper.step_4.children.active_glass_type')}
            contentText={this.getSelectedItem('doorGlazing', 'selectedDoorGlazingActive')}
          />
          {
            selectedDoorGlazingPassive !== null
            && ([
              <StepperText
                key="glass_family"
                title={t('stepper.step_4.children.passive_glass_family')}
                contentText={this.getSelectedItem('doorGlazingType', 'selectedDoorGlazingTypePassive')}
              />,
              <StepperText
                key="glass_type"
                title={t('stepper.step_4.children.passive_glass_type')}
                contentText={this.getSelectedItem('doorGlazing', 'selectedDoorGlazingPassive')}
              />,
              <StepperText
                key="glass_pattern"
                title={t('stepper.step_4.children.passive_glass_pattern')}
                contentText={this.getSelectedItem('doorGlassPattern', 'selectedDoorGlassPatternPassive')}
              />,
            ])
          }
        </Timeline.Item>

        <Timeline.Item className={this.getStepClassName(5)}>
          <button
            type="button"
            className="stepper-content-title"
            data-step-id={5}
            onClick={this.handleJumpToStep}
          >
            <span>05 </span>
            {t('stepper.step_5.title')}
          </button>
          <StepperText
            title={t('stepper.step_5.children.door_case')}
            contentText={this.getSelectedItem('doorAvailableColors', 'selectedDoorFrameColor')}
          />
          <StepperText
            title={t('stepper.step_5.children.active_door_sheet')}
            contentText={this.getSelectedItem('doorAvailableColors', 'selectedDoorBodyColorActive')}
          />
          {
            selectedDoorInlay1ColorActive !== null
            && (
              <StepperText
                title={t('stepper.step_5.children.active_door_inlay')}
                contentText={this.getSelectedItem('doorAvailableColors', 'selectedDoorInlay1ColorActive')}
              />
            )
          }
          {
            selectedDoorInlay2ColorActive !== null
            && (
              <StepperText
                title={t('stepper.step_5.children.active_door_inlay2')}
                contentText={this.getSelectedItem('doorAvailableColors', 'selectedDoorInlay2ColorActive')}
              />
            )
          }

          {
            wings === 2
            && (
              <StepperText
                title={t('stepper.step_5.children.passive_door_sheet')}
                contentText={this.getSelectedItem('doorAvailableColors', 'selectedDoorBodyColorPassive')}
              />
            )
          }

          {
            wings === 2 && selectedDoorInlay1ColorPassive !== null
            && (
              <StepperText
                title={t('stepper.step_5.children.passive_door_inlay')}
                contentText={this.getSelectedItem('doorAvailableColors', 'selectedDoorInlay1ColorPassive')}
              />
            )
          }
          {
            wings === 2 && selectedDoorInlay2ColorPassive !== null
            && (
              <StepperText
                title={t('stepper.step_5.children.passive_door_inlay2')}
                contentText={this.getSelectedItem('doorAvailableColors', 'selectedDoorInlay2ColorPassive')}
              />
            )
          }
        </Timeline.Item>

        <Timeline.Item className={this.getStepClassName(6)}>
          <button
            type="button"
            className="stepper-content-title"
            data-step-id={6}
            onClick={this.handleJumpToStep}
          >
            <span>06 </span>
            {t('stepper.step_6.title')}
          </button>
          <StepperText
            title={t('stepper.step_6.children.handle')}
            contentText={this.getSelectedItem('doorAccessory', 'selectedDoorAccessory')}
          />
          {/*
            Szellőző
            <StepperText
              title={t('stepper.step_6.children.fan')}
              contentText={selectedDoorAccessory === null ? 'nem' : 'igen'}
            />
          */}
        </Timeline.Item>
        <Timeline.Item className={this.getStepClassName(7)}>
          <button
            type="button"
            className="stepper-content-title"
            data-step-id={7}
            onClick={this.handleJumpToStep}
          >
            <span>07 </span>
            {t('stepper.step_7.title')}
          </button>
        </Timeline.Item>
      </Timeline>
    );
  }
}

Stepper.propTypes = {
  collapsed: PropTypes.bool,
  configurator: PropTypes.shape().isRequired,
  availableOptions: PropTypes.shape().isRequired,
  selectedOptions: PropTypes.shape().isRequired,
  dispatchSelectStep: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

Stepper.defaultProps = {
  collapsed: false,
};

const mapStateToProps = (store) => ({
  availableOptions: store.availableOptions,
  selectedOptions: store.selectedOptions,
  configurator: store.configurator,
});

const mapDispatchToProps = {
  dispatchSelectStep: selectStep,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Stepper));
