/* eslint-disable no-shadow */

import { takeEvery, put, call, select, all } from 'redux-saga/effects';
import { find, sortBy } from 'lodash';
import {
  doorFrameElementNames,
  TOKBANYILO_DOOR_DESIGN,
  DOOR_FAN,
} from 'consts/consts';
import {
  createPatternObject,
  createRectBorder, createRectBorderRounded,
  createPolyBorder, createPolyBorderRotated,
  createPolyBorderDiagonal,
  createCircleBorder,
  createPattern,
  createWindowShape,
} from 'utils/canvasFunctions';
import RALCOLORS from 'utils/ralColors';
import { convertRalToHex } from 'utils/utils';
import * as API from 'services/api';
import { host } from 'services/config';
import { getInitRoomImages } from 'utils/envirionment';
import { getGlassImage } from 'utils/images';
import * as STEP02 from 'utils/step02';
import * as STEP03 from 'utils/step03';
import {
  CANVAS_INIT_CANVAS,
  CANVAS_INITIALIZED,
  CONFIGURATOR_COMPONENT_REQUESTED, CONFIGURATOR_COMPONENT_SUCCEEDED, CONFIGURATOR_COMPONENT_FAILED,
} from 'consts/actionTypes';
import { frontCanvas, patterns } from 'models';

const getStore = (store) => store;
const getAvailableOptionsStore = (store) => store.availableOptions;
const getSelectedOptionsStore = (store) => store.selectedOptions;

export function* renderCanvas() {
  try {
    const store = yield select(getStore);
    const availableOptionsStore = yield select(getAvailableOptionsStore);
    const selectedOptionsStore = yield select(getSelectedOptionsStore);
    const canvas = frontCanvas.getCanvas();

    // STEP 00
    // RESET CANVAS | clear everything from the canvas
    canvas.remove(...canvas.getObjects().concat());

    // STEP 01
    // ADD ROOM LAYERS | add selected room layers (5 items: floor multiple&darken, wall multiple&darken, room objects)
    const wallColor = convertRalToHex(availableOptionsStore.doorAvailableColors[selectedOptionsStore.selectedWallColor].attributes.name);
    const floorColor = convertRalToHex(availableOptionsStore.doorAvailableColors[selectedOptionsStore.selectedFloorColor].attributes.name);
    const selectedLocation = availableOptionsStore.doorAvailableLocation[selectedOptionsStore.selectedLocation];
    const locationImages = availableOptionsStore.doorAvailableLocationAttachedImage;
    const fchFloorMultiply = locationImages[selectedLocation.relationships.fch_floor_multiply.data.id].attributes.uri.url;
    const fchFloorScreen = locationImages[selectedLocation.relationships.fch_floor_screen.data.id].attributes.uri.url;
    const fchObjects = locationImages[selectedLocation.relationships.fch_objects.data.id].attributes.uri.url;
    const fchWallMultiply = locationImages[selectedLocation.relationships.fch_wall_multiply.data.id].attributes.uri.url;
    const fchWallScreen = locationImages[selectedLocation.relationships.fch_wall_screen.data.id].attributes.uri.url;
    const locationImageUrls = { fchFloorMultiply, fchFloorScreen, fchObjects, fchWallMultiply, fchWallScreen };
    const initRoomImages = yield call(getInitRoomImages, wallColor, floorColor, locationImageUrls);
    canvas.add(...Object.keys(initRoomImages).map((layer) => initRoomImages[layer]));

    // STEP 02
    // ADD SELECTED DOOR TYPE | render door with helper function
    const doorElements = STEP02.createSelectedDoorType(store).map((obj) => {
      if (doorFrameElementNames.includes(obj.doorElement)) {
        const doorPatternImage = patterns.getPattern(`${selectedOptionsStore.selectedDoorFrameColor}-V`);
        if (doorPatternImage) {
          // IMAGE PATTERN
          doorPatternImage.clone((clonedImage) => {
            clonedImage.scale(0.5);
            const doorPattern = createPattern(
              clonedImage,
              'repeat',
            );
            obj.set('fill', doorPattern);
          });
        } else {
          // RAL COLOR
          const { name } = availableOptionsStore.doorAvailableColors[selectedOptionsStore.selectedDoorFrameColor].attributes;
          obj.set('fill', RALCOLORS[name]);
        }
      }

      if (obj.doorElement === 'doorShape') {
        const doorShapeAttributes = availableOptionsStore.doorPatternInstance[selectedOptionsStore.selectedDoorPatternInstanceActive].attributes;
        const patternDirection = doorShapeAttributes.fch_pattern_direction[0] === 'V' ? 'V' : 'H';
        const doorPatternImage = patterns.getPattern(`${selectedOptionsStore.selectedDoorBodyColorActive}-${patternDirection}`);
        if (doorPatternImage) {
          // IMAGE PATTERN
          doorPatternImage.clone((clonedImage) => {
            clonedImage.scale(0.5);
            const doorPattern = createPattern(
              clonedImage,
              'repeat',
            );
            obj.set('fill', doorPattern);
          });
        } else {
          // RAL COLOR
          const { name } = availableOptionsStore.doorAvailableColors[selectedOptionsStore.selectedDoorBodyColorActive].attributes;
          obj.set('fill', RALCOLORS[name]);
        }
      }

      if (obj.doorElement === 'doorShapePassive') {
        const doorShapePassiveAttributes = availableOptionsStore.doorPatternInstance[selectedOptionsStore.selectedDoorPatternInstancePassive].attributes;
        const patternDirection = doorShapePassiveAttributes.fch_pattern_direction[0] === 'V' ? 'V' : 'H';
        const doorPatternImage = patterns.getPattern(`${selectedOptionsStore.selectedDoorBodyColorPassive}-${patternDirection}`);
        if (doorPatternImage) {
          // IMAGE PATTERN
          doorPatternImage.clone((clonedImage) => {
            clonedImage.scale(0.5);
            const doorPattern = createPattern(
              clonedImage,
              'repeat',
            );
            obj.set('fill', doorPattern);
          });
        } else {
          // RAL COLOR
          const { name } = availableOptionsStore.doorAvailableColors[selectedOptionsStore.selectedDoorBodyColorPassive].attributes;
          obj.set('fill', RALCOLORS[name]);
        }
      }

      if (obj.doorElement === 'windowShape') {
        const windowShapeImage = patterns.getPattern(`${selectedOptionsStore.selectedWindowGlassPattern}`);
        windowShapeImage.clone((clonedImage) => {
          clonedImage.scale(2.2);
          const windowShapePattern = createPattern(
            clonedImage,
            'repeat',
            {
              x: -selectedOptionsStore.doorActiveWallOpeningWidth,
              y: -((clonedImage.getScaledHeight() - selectedOptionsStore.doorWallOpeningHeight) / 2) + 250,
            },
          );
          obj.set('fill', windowShapePattern);
        });
      }

      return obj;
    });
    canvas.add(...doorElements);

    // STEP 03
    // ADD DOOR PATTERNS (ACTIVE)
    const alignObject = find(canvas.getObjects(), { name: 'doorShape' });
    const { width, height } = alignObject;
    const doorPatternAttributes = availableOptionsStore.doorPatternInstance[selectedOptionsStore.selectedDoorPatternInstanceActive].attributes;
    const patternShapes = doorPatternAttributes.fch_shapes;
    const patternElements = createPatternObject(width, height, patternShapes);
    const doorPatternActiveObjects = yield all(patternElements.shapes.filter((obj) => obj.patternDirection !== null).map((obj) => (
      new Promise((resolve) => {
        const doorGlassShapeImage = obj.darkness === 'normal'
          ? patterns.getPattern(`${selectedOptionsStore.selectedDoorInlay1ColorActive}-${obj.patternDirection}`)
          : patterns.getPattern(`${selectedOptionsStore.selectedDoorInlay2ColorActive}-${obj.patternDirection}`);
        if (doorGlassShapeImage) {
          doorGlassShapeImage.clone((cloned) => {
            const clonedImage = cloned;
            clonedImage.scale(0.5);
            const colorPattern = createPattern(
              clonedImage,
              'repeat',
            );
            const shape = createWindowShape(obj, alignObject, colorPattern);
            shape.layerIndex = alignObject.layerIndex + 1;
            resolve(shape);
          });
        } else {
          // RAL COLOR
          const selectedColor = obj.darkness === 'normal'
            ? selectedOptionsStore.selectedDoorInlay1ColorActive
            : selectedOptionsStore.selectedDoorInlay2ColorActive;
          const { name } = availableOptionsStore.doorAvailableColors[selectedColor].attributes;
          const shape = createWindowShape(obj, alignObject, RALCOLORS[name]);
          shape.layerIndex = alignObject.layerIndex + 1;
          resolve(shape);
        }
      })
    )));
    canvas.add(...doorPatternActiveObjects);
    const metalStripes = patternElements.shapes.filter((obj) => obj.patternDirection === null && obj.darkness === 'dark').map((obj) => {
      const shape = createWindowShape(obj, alignObject, '#818286');
      shape.layerIndex = alignObject.layerIndex + 1;
      return shape;
    });
    canvas.add(...metalStripes);

    // ADD DOOR PATTERNS (PASSIVE)
    const alignObjectPassive = find(canvas.getObjects(), { name: 'doorShapePassive' });
    if (alignObjectPassive) {
      // Stores attributes of the door patterns.
      const doorPatternAttributesPassive = availableOptionsStore.doorPatternInstance[selectedOptionsStore.selectedDoorPatternInstancePassive].attributes;
      const patternShapesPassive = doorPatternAttributesPassive.fch_shapes;
      const patternElementsPassive = createPatternObject(alignObjectPassive.width, alignObjectPassive.height, patternShapesPassive);

      // Draws the chosen pattern onto the door, without the metal stripe(s).
      const doorPatternActiveObjectsPassive = yield all(patternElementsPassive.shapes.filter((obj) => obj.patternDirection !== null).map((obj) => (
        new Promise((resolve) => {
          const doorGlassShapeImage = obj.darkness === 'normal'
            ? patterns.getPattern(`${selectedOptionsStore.selectedDoorInlay1ColorPassive}-${obj.patternDirection}`)
            : patterns.getPattern(`${selectedOptionsStore.selectedDoorInlay2ColorPassive}-${obj.patternDirection}`);
          if (doorGlassShapeImage) {
            doorGlassShapeImage.clone((cloned) => {
              const clonedImage = cloned;
              clonedImage.scale(0.5);
              const colorPattern = createPattern(
                clonedImage,
                'repeat',
              );
              const shape = createWindowShape(obj, alignObjectPassive, colorPattern);
              shape.layerIndex = alignObject.layerIndex + 1;
              /*
               * Reflects the pattern image shape against the shape of the active door.
               * Only the shape is reflected. The images, that make up the shape, are not reflected.
               * If the images should be reflected as well, use: clonedImage.set('flipY', true);
               */
              canvas.add(STEP03.setDoorPatternPosition(
                shape,
                obj,
                alignObject,
                alignObjectPassive,
                selectedOptionsStore,
                'patternImage',
              ));
              resolve(shape);
            });
          } else {
            // RAL COLOR
            const selectedColor = obj.darkness === 'normal'
              ? selectedOptionsStore.selectedDoorInlay1ColorPassive
              : selectedOptionsStore.selectedDoorInlay2ColorPassive;
            const { name } = availableOptionsStore.doorAvailableColors[selectedColor].attributes;
            const shape = createWindowShape(obj, alignObjectPassive, RALCOLORS[name]);
            shape.layerIndex = alignObject.layerIndex + 1;
            resolve(shape);
          }
        })
      )));
      canvas.add(...doorPatternActiveObjectsPassive);

      // Draws the metal stripe(s) onto the door, found on the chosen pattern.
      const metalStripesPassive = patternElementsPassive.shapes.filter((obj) => obj.patternDirection === null && obj.darkness === 'dark').map((obj) => {
        const shape = createWindowShape(obj, alignObjectPassive, '#818286');
        shape.layerIndex = alignObjectPassive.layerIndex + 1;
        // Reflects the pattern metal string(s) shape against the shape of the active door.
        canvas.add(STEP03.setDoorPatternPosition(
          shape,
          obj,
          alignObject,
          alignObjectPassive,
          selectedOptionsStore,
          'patternMetalStripe',
        ));
        return shape;
      });
      canvas.add(...metalStripesPassive);
    }

    // STEP 04/1 GLASSES (ACTIVE)
    if (selectedOptionsStore.selectedDoorGlazingActive) {
      const glassShapes = availableOptionsStore.doorGlazing[selectedOptionsStore.selectedDoorGlazingActive].attributes.fch_shapes;
      const glasses = createPatternObject(width, height, glassShapes);

      let borders = [];
      const canvasObjects = yield all(glasses.shapes.map((obj) => (
        new Promise((resolve) => {
          const doorGlassShapeImage = obj.darkness === 'glass'
            ? patterns.getPattern(`${selectedOptionsStore.selectedDoorGlassPatternActive}`)
            : patterns.getPattern(`${selectedOptionsStore.selectedDoorBodyColorActive}-H`);

          doorGlassShapeImage.clone((clonedImage) => {
            clonedImage.scale(2.2);
            const glassPattern = createPattern(
              clonedImage,
              'no-repeat',
              obj.darkness === 'glass'
                ? {
                  x: -obj.left,
                  y: -(((clonedImage.getScaledHeight() - selectedOptionsStore.doorWallOpeningHeight) / 2) + obj.top),
                }
                : {},
            );
            const shape = createWindowShape(obj, alignObject, glassPattern);
            shape.layerIndex = 40;
            resolve(shape);
          });
        })
      )));

      // WINDOWS BORDERS
      if (
        selectedOptionsStore.selectedDoorGlazingTypeActive === '85f70c3d-50e2-4aba-bd38-b7e910dc7ac0'
        || selectedOptionsStore.selectedDoorGlazingTypeActive === 'eedcb73e-4fb8-4675-a553-14515d3f28da'
        || selectedOptionsStore.selectedDoorGlazingTypePassive === '85f70c3d-50e2-4aba-bd38-b7e910dc7ac0'
        || selectedOptionsStore.selectedDoorGlazingTypePassive === 'eedcb73e-4fb8-4675-a553-14515d3f28da'
      ) {
        borders = yield all(canvasObjects.map((glassShapeItem) => (
          new Promise((resolve) => {
            const glassShape = glassShapeItem;
            const doorPatternImage = patterns.getPattern(`${selectedOptionsStore.selectedDoorBodyColorActive}-V`);
            if (doorPatternImage) {
              // IMAGE PATTERN
              doorPatternImage.clone((clonedImage) => {
                clonedImage.scale(0.5);
                const doorPattern = createPattern(
                  clonedImage,
                  'repeat',
                );
                const { left, top, height, width } = glassShape;
                let objects = [];
                if (glassShape.isGlazing) {
                  switch (glassShape.frameType) {
                    case 'rect': {
                      objects = createRectBorder(left, top, height, width, doorPattern);
                      break;
                    }

                    case 'diagonalRect': {
                      objects = createPolyBorderDiagonal(left, top, height, width, doorPattern);
                      break;
                    }

                    case 'rectRounded': {
                      objects = createRectBorderRounded(glassShape, doorPattern);
                      glassShape.strokeWidth = 1;
                      glassShape.stroke = 'black';
                      break;
                    }

                    case 'polygon': {
                      objects = createPolyBorder(glassShape, doorPattern);
                      break;
                    }

                    case 'polygonRotated': {
                      objects = createPolyBorderRotated(glassShape, doorPattern);
                      break;
                    }

                    case 'circle': {
                      objects = createCircleBorder(glassShape, doorPattern);
                      glassShape.strokeWidth = 1;
                      glassShape.stroke = 'black';
                      break;
                    }

                    default:
                  }
                }

                resolve(objects);
              });
            } else {
              // RAL COLOR
              const { name } = availableOptionsStore.doorAvailableColors[selectedOptionsStore.selectedDoorBodyColorActive].attributes;
              const { left, top, height, width } = glassShape;
              const objects = glassShape.frameType === 'rect' && glassShape.isGlazing
                ? createRectBorder(left, top, height, width, RALCOLORS[name])
                : [];
              resolve(objects);
            }
          })
        )));
      }
      canvas.add(...canvasObjects, ...borders.flat(Infinity));
    }

    // STEP 04/2 - GLASSES (PASSIVE)
    if (selectedOptionsStore.selectedDoorWingType === '88a0b4b0-c577-40e2-b966-16160c6c4127' && selectedOptionsStore.selectedDoorGlazingPassive) {
      const alignObject = find(canvas.getObjects(), { name: 'doorShapePassive' });
      const { width, height } = alignObject;
      const glassShapes = availableOptionsStore.doorGlazing[selectedOptionsStore.selectedDoorGlazingPassive].attributes.fch_shapes;
      const glasses = createPatternObject(width, height, glassShapes);

      let borders = [];
      const canvasObjects = yield all(glasses.shapes.map((obj) => (
        new Promise((resolve) => {
          const doorGlassShapeImage = patterns.getPattern(`${selectedOptionsStore.selectedDoorGlassPatternPassive}`);
          doorGlassShapeImage.clone((clonedImage) => {
            clonedImage.scale(2.2);
            const glassPattern = createPattern(
              clonedImage,
              'no-repeat',
              {
                x: -obj.left,
                y: -(((clonedImage.getScaledHeight() - selectedOptionsStore.doorWallOpeningHeight) / 2) + obj.top),
              },
            );

            const shape = createWindowShape(obj, alignObject, glassPattern);
            shape.layerIndex = alignObject.layerIndex + 2;
            resolve(shape);
          });
        })
      )));

      // WINDOWS BORDERS
      if (
        selectedOptionsStore.workingDoorGlazingTypePassive === '85f70c3d-50e2-4aba-bd38-b7e910dc7ac0'
        || selectedOptionsStore.workingDoorGlazingTypePassive === 'eedcb73e-4fb8-4675-a553-14515d3f28da'
      ) {
        borders = yield all(canvasObjects.map((glassShapeItem) => (
          new Promise((resolve) => {
            const glassShape = glassShapeItem;
            const doorPatternImage = patterns.getPattern(`${selectedOptionsStore.selectedDoorBodyColorPassive}-H`);
            if (doorPatternImage) {
              // IMAGE PATTERN
              doorPatternImage.clone((clonedImage) => {
                clonedImage.scale(2.2);
                const doorPattern = createPattern(
                  clonedImage,
                  'repeat',
                );
                const { left, top, height, width } = glassShape;
                let objects = [];
                if (glassShape.isGlazing) {
                  switch (glassShape.frameType) {
                    case 'rect': {
                      objects = createRectBorder(left, top, height, width, doorPattern);
                      break;
                    }

                    case 'diagonalRect': {
                      objects = createPolyBorderDiagonal(left, top, height, width, doorPattern);
                      break;
                    }

                    case 'rectRounded': {
                      objects = createRectBorderRounded(glassShape, doorPattern);
                      glassShape.strokeWidth = 1;
                      glassShape.stroke = 'black';
                      break;
                    }

                    case 'polygon': {
                      objects = createPolyBorder(glassShape, doorPattern);
                      break;
                    }

                    case 'polygonRotated': {
                      objects = createPolyBorderRotated(glassShape, doorPattern);
                      break;
                    }

                    case 'circle': {
                      objects = createCircleBorder(glassShape, doorPattern);
                      glassShape.strokeWidth = 1;
                      glassShape.stroke = 'black';
                      break;
                    }

                    default:
                  }
                }

                resolve(objects);
              });
            } else {
              // RAL COLOR
              const { name } = availableOptionsStore.doorAvailableColors[selectedOptionsStore.selectedDoorBodyColorPassive].attributes;
              const { left, top, height, width } = glassShape;
              const objects = glassShape.frameType === 'rect' && glassShape.isGlazing
                ? createRectBorder(left, top, height, width, RALCOLORS[name])
                : [];
              resolve(objects);
            }
          })
        )));
      }
      canvas.add(...canvasObjects, ...borders.flat(Infinity));
    }

    // STEP 07
    // DOOR ACCESSORY
    const doorAccessoryPromise = new Promise((resolve) => {
      const selectedAccesoryImage = patterns.getPattern(selectedOptionsStore.selectedDoorAccessory);
      selectedAccesoryImage.clone((cloned) => {
        resolve(cloned);
      });
    });
    // DOOR FAN
    const doorFanPromise = new Promise((resolve) => {
      if (!selectedOptionsStore.selectedDoorFanOption) {
        resolve(null);
      }

      const selectedFanImage = patterns.getPattern(DOOR_FAN);
      if (selectedFanImage) {
        selectedFanImage.clone((cloned) => {
          resolve(cloned);
        });
      } else {
        resolve(null);
      }
    });

    // STEP 10
    // SORT CANVAS LAYERS | reorder layers (objects, shadows)
    canvas._objects = sortBy(canvas._objects, ['layerIndex']);

    // STEP 10
    // RESIZE CANVAS OBJECTS
    canvas.setZoom(0.18);

    // FINALLY - RENDER CANVAS ITEMS
    Promise.all([doorAccessoryPromise, doorFanPromise]).then(async (promiseData) => {
      const activeDoor = find(canvas.getObjects(), { name: 'doorShape' });
      const passiveDoor = find(canvas.getObjects(), { name: 'doorShapePassive' });

      const doorAccessory = promiseData[0];
      const doorFan = promiseData[2];
      if (doorAccessory) {
        if (
          selectedOptionsStore.selectedDoorWingType === '88a0b4b0-c577-40e2-b966-16160c6c4127'
          && (
            selectedOptionsStore.selectedDoorDesign === 'e5e8f1f5-bc60-4773-bd51-9f984da3f0da'
            || selectedOptionsStore.selectedDoorDesign === 'ea7d7e0f-49a3-4569-95ad-3440a2489d56'
          )
        ) {
          const image = await new Promise((resolve) => {
            doorAccessory.clone((cloned) => {
              resolve(cloned);
            });
          });

          canvas.add(STEP03.setDoorAccessoryPosition(
            image,
            passiveDoor,
            selectedOptionsStore.selectedDoorOpeningDirection,
            true,
          ));
        }
        canvas.add(STEP03.setDoorAccessoryPosition(
          doorAccessory,
          activeDoor,
          selectedOptionsStore.selectedDoorOpeningDirection,
          selectedOptionsStore.selectedDoorDesign === TOKBANYILO_DOOR_DESIGN,
        ));
      }

      if (doorFan) {
        doorFan.left = activeDoor.left + ((activeDoor.width - doorFan.width) / 2);
        doorFan.top = activeDoor.top + (activeDoor.height - 150);
        canvas.add(doorFan);
      }

      canvas.renderAll();
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}

function* initCanvas(action) {
  try {
    const availableOptionsStore = yield select(getAvailableOptionsStore);
    const selectedOptionsStore = yield select(getSelectedOptionsStore);
    frontCanvas.setCanvas(action.payload.canvasId);

    // GET BASE DOOR ACCESSORY
    const selectedDoorAccessory = availableOptionsStore.doorAccessory[selectedOptionsStore.selectedDoorAccessory];
    const selectedDoorAccessory2 = availableOptionsStore.doorAccessory['c9a07c9d-abdf-491b-84d6-9dca46c3884d'];
    const selectedAccesoryImage = availableOptionsStore.doorAccessoryAttachedImage[selectedDoorAccessory.relationships.fch_attached_image.data.id];
    const selectedAccesory2Image = availableOptionsStore.doorAccessoryAttachedImage[selectedDoorAccessory2.relationships.fch_attached_image.data.id];
    const doorAccessoryImage = yield call(getGlassImage, selectedAccesoryImage.attributes.uri.url);
    const doorAccessory2Image = yield call(getGlassImage, selectedAccesory2Image.attributes.uri.url);
    patterns.setPatterns([
      { name: selectedOptionsStore.selectedDoorAccessory, value: doorAccessoryImage },
      { name: 'c9a07c9d-abdf-491b-84d6-9dca46c3884d', value: doorAccessory2Image },
    ]);

    // GET BASE DOOR PATTERNS
    const selectedPattern = availableOptionsStore.doorAvailableColors[selectedOptionsStore.selectedDoorBodyColorActive];
    const selectedPatternImageH = availableOptionsStore.doorAvailableColorsAttachedImage[selectedPattern.relationships.fch_horizontal_image.data.id];
    const selectedPatternImageV = availableOptionsStore.doorAvailableColorsAttachedImage[selectedPattern.relationships.fch_vertical_image.data.id];
    const patternH3430H = yield call(getGlassImage, selectedPatternImageH.attributes.uri.url);
    const patternH3430V = yield call(getGlassImage, selectedPatternImageV.attributes.uri.url);
    patterns.setPatterns([
      { name: `${selectedOptionsStore.selectedDoorBodyColorActive}-H`, value: patternH3430H },
      { name: `${selectedOptionsStore.selectedDoorBodyColorActive}-V`, value: patternH3430V },
    ]);

    const selectedGlass = availableOptionsStore.doorGlassPattern[selectedOptionsStore.selectedWindowGlassPattern];
    const selectedGlassImage = availableOptionsStore.doorGlassPatternAttachedImage[selectedGlass.relationships.fch_attached_image.data.id];
    const patternSavmart = yield call(getGlassImage, selectedGlassImage.attributes.uri.url);
    patterns.setPatterns([{ name: `${selectedOptionsStore.selectedWindowGlassPattern}`, value: patternSavmart }]);

    // INITIALIZE CANVAS
    yield call(renderCanvas);
    yield put({ type: CANVAS_INITIALIZED });
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}

function* getConfiguratorComponent(action) {
  try {
    const { componentType, query } = action.payload;
    const choices = {
      data: [],
      included: [],
    };

    let hasNext = true;
    let queryString = `${host}/jsonapi${componentType}?filter[status][value]=1${query}`;

    do {
      const query = yield call(API.getConfiguratorComponent, queryString);
      choices.data = [...choices.data, ...query.data];
      choices.included = [...choices.included, ...query.included || []];
      queryString = query.links.next && query.links.next.href !== undefined
        ? query.links.next.href // query.links.next.href.substr(queryString.length, query.links.next.href.length)
        : hasNext = false;
    } while (hasNext);

    yield put({
      type: CONFIGURATOR_COMPONENT_SUCCEEDED,
      payload: {
        choices: choices.data,
        included: choices.included,
        componentType,
      },
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
    yield put({
      type: CONFIGURATOR_COMPONENT_FAILED,
    });
  }
}

export default [
  takeEvery(CANVAS_INIT_CANVAS, initCanvas),
  takeEvery(CONFIGURATOR_COMPONENT_REQUESTED, getConfiguratorComponent),
];
