import {
  DOOR_LOAD_BEGIN,
  CANVAS_INIT_CANVAS,
  CONFIGURATOR_SELECT_STEP,
  CONFIGURATOR_COMPONENT_REQUESTED,
  CONFIGURATOR_SELECT_WALL_COLOR,
  CONFIGURATOR_SELECT_FLOOR_COLOR,
  CONFIGURATOR_SELECT_DOOR_FAMILY,
  CONFIGURATOR_SELECT_LOCATION,
  CONFIGURATOR_SELECT_DOOR_WING_TYPE,
  CONFIGURATOR_SELECT_DOOR_OPENING_DIRECTION,
  CONFIGURATOR_SELECT_DOOR_DESIGN,
  CONFIGURATOR_SELECT_DOOR_DESIGN_EXTRA,
  CONFIGURATOR_CHANGE_DOOR_DIMENSIONS,
  CONFIGURATOR_CHANGE_WINDOW_GLASS_PATTERN,
  CONFIGURATOR_SELECT_DOOR_GLAZING_TYPE,
  CONFIGURATOR_SELECT_DOOR_GLAZING,
  CONFIGURATOR_SELECT_DOOR_GLASS_PATTERN,
  CONFIGURATOR_SELECT_DOOR_FRAME_COLOR,
  CONFIGURATOR_SELECT_DOOR_BODY_COLOR,
  CONFIGURATOR_SELECT_DOOR_INLAY1_COLOR,
  CONFIGURATOR_SELECT_DOOR_INLAY2_COLOR,
  CONFIGURATOR_SELECT_WORKING_WING,
  CONFIGURATOR_SELECT_DOOR_PATTERN_INSTANCE,
  CONFIGURATOR_SELECT_DOOR_ACCESSORY,
  CONFIGURATOR_SELECT_DOOR_FAN,
  CONFIGURATOR_GET_PDF_REQUESTED,
  CONFIGURATOR_GET_JPG_REQUESTED,
  CONFIGURATOR_GET_URL_REQUESTED,
  DOOR_SET_ATTRIBUTE,
  DOOR_LOAD_DOOR_REQUESTED,
} from 'consts/actionTypes';

export const openLoadModal = () => ({
  type: DOOR_LOAD_BEGIN,
});

export const initCanvas = (canvasId) => ({
  type: CANVAS_INIT_CANVAS,
  payload: {
    canvasId,
  },
});

export const selectStep = (step) => ({
  type: CONFIGURATOR_SELECT_STEP,
  payload: {
    step,
  },
});

export const getConfiguratorComponent = (componentType, query = '') => ({
  type: CONFIGURATOR_COMPONENT_REQUESTED,
  payload: {
    componentType,
    query,
  },
});

export const selectWallColor = (selectedWallColor) => ({
  type: CONFIGURATOR_SELECT_WALL_COLOR,
  payload: {
    selectedWallColor,
  },
});

export const selectFloorColor = (selectedFloorColor) => ({
  type: CONFIGURATOR_SELECT_FLOOR_COLOR,
  payload: {
    selectedFloorColor,
  },
});

export const selectDoorFamily = (selectedDoorFamily) => ({
  type: CONFIGURATOR_SELECT_DOOR_FAMILY,
  payload: {
    selectedDoorFamily,
  },
});

export const selectLocation = (selectedLocation) => ({
  type: CONFIGURATOR_SELECT_LOCATION,
  payload: {
    selectedLocation,
  },
});

export const selectDoorWingType = (selectedDoorWingType) => ({
  type: CONFIGURATOR_SELECT_DOOR_WING_TYPE,
  payload: {
    selectedDoorWingType,
  },
});

export const selectDoorOpeningDirection = (selectedDoorOpeningDirection) => ({
  type: CONFIGURATOR_SELECT_DOOR_OPENING_DIRECTION,
  payload: {
    selectedDoorOpeningDirection,
  },
});

export const selectDoorDesign = (selectedDoorDesign) => ({
  type: CONFIGURATOR_SELECT_DOOR_DESIGN,
  payload: {
    selectedDoorDesign,
  },
});

export const selectDoorDesignExtra = (selectedDoorDesignExtra) => ({
  type: CONFIGURATOR_SELECT_DOOR_DESIGN_EXTRA,
  payload: {
    selectedDoorDesignExtra,
  },
});

export const changeDoorDimensions = (dimensions) => ({
  type: CONFIGURATOR_CHANGE_DOOR_DIMENSIONS,
  payload: {
    dimensions,
  },
});

export const selectWindowGlassPattern = (selectedWindowGlassPattern) => ({
  type: CONFIGURATOR_CHANGE_WINDOW_GLASS_PATTERN,
  payload: {
    selectedWindowGlassPattern,
  },
});

export const selectDoorGlazingType = (selectedDoorGlazingType) => ({
  type: CONFIGURATOR_SELECT_DOOR_GLAZING_TYPE,
  payload: {
    selectedDoorGlazingType,
  },
});

export const selectDoorGlazing = (selectedDoorGlazing) => ({
  type: CONFIGURATOR_SELECT_DOOR_GLAZING,
  payload: {
    selectedDoorGlazing,
  },
});

export const selectDoorGlassPattern = (selectedDoorGlassPattern) => ({
  type: CONFIGURATOR_SELECT_DOOR_GLASS_PATTERN,
  payload: {
    selectedDoorGlassPattern,
  },
});

export const selectDoorFrameColor = (selectedDoorFrameColor) => ({
  type: CONFIGURATOR_SELECT_DOOR_FRAME_COLOR,
  payload: {
    selectedDoorFrameColor,
  },
});

export const selectDoorBodyColor = (selectedDoorBodyColor) => ({
  type: CONFIGURATOR_SELECT_DOOR_BODY_COLOR,
  payload: {
    selectedDoorBodyColor,
  },
});

export const selectDoorInlay1Color = (selectedDoorInlay1Color) => ({
  type: CONFIGURATOR_SELECT_DOOR_INLAY1_COLOR,
  payload: {
    selectedDoorInlay1Color,
  },
});

export const selectDoorInlay2Color = (selectedDoorInlay2Color) => ({
  type: CONFIGURATOR_SELECT_DOOR_INLAY2_COLOR,
  payload: {
    selectedDoorInlay2Color,
  },
});

export const selectDoorPatternInstance = (selectedDoorPattern, selectedDoorPatternInstance, selectedWorkingWing) => ({
  type: CONFIGURATOR_SELECT_DOOR_PATTERN_INSTANCE,
  payload: {
    selectedDoorPattern,
    selectedDoorPatternInstance,
    selectedWorkingWing,
  },
});

export const selectWorkingWing = (selectedWorkingWing) => ({
  type: CONFIGURATOR_SELECT_WORKING_WING,
  payload: {
    selectedWorkingWing,
  },
});

export const selectDoorAccessory = (selectedDoorAccessory) => ({
  type: CONFIGURATOR_SELECT_DOOR_ACCESSORY,
  payload: {
    selectedDoorAccessory,
  },
});

export const selectDoorFan = (selectedDoorFanOption) => ({
  type: CONFIGURATOR_SELECT_DOOR_FAN,
  payload: {
    selectedDoorFanOption,
  },
});

export const setDoorAttribute = (attribute, value) => ({
  type: DOOR_SET_ATTRIBUTE,
  payload: {
    attribute,
    value,
  },
});

export const loadDoor = (id) => ({
  type: DOOR_LOAD_DOOR_REQUESTED,
  payload: {
    id,
  },
});

export const getPdf = () => ({
  type: CONFIGURATOR_GET_PDF_REQUESTED,
});

export const getJpg = () => ({
  type: CONFIGURATOR_GET_JPG_REQUESTED,
});

export const getUrl = () => ({
  type: CONFIGURATOR_GET_URL_REQUESTED,
});
