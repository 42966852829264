import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { find } from 'lodash';
import {
  getConfiguratorComponent,
  selectDoorPatternInstance,
} from 'actions/canvasActions';
import {
  NODE_DOOR_PATTERN,
  NODE_DOOR_PATTERN_INSTANCE,
  ACTIVE_WING, PASSIVE_WING,
} from 'consts/consts';
import Spinner from 'components/Spinner/ComponentSpinner';
import WingChooser from 'components/WingChooser/WingChooser';
import TypeSelector from 'components/TypeSelector/TypeSelector';

class Step03 extends Component {
  constructor(props) {
    super(props);

    const { selectedDoorPatternActive, selectedDoorPatternPassive } = props;

    this.state = {
      selectedDoorPatternActive,
      selectedDoorPatternPassive,
    };
  }

  componentDidMount() {
    const { dispatchGetConfiguratorInstanceComponent, selectedDoorPatternActive } = this.props;
    dispatchGetConfiguratorInstanceComponent(NODE_DOOR_PATTERN, '&include=fch_colorable_parts');
    dispatchGetConfiguratorInstanceComponent(NODE_DOOR_PATTERN_INSTANCE, `&filter[fch_door_pattern.id][value]=${selectedDoorPatternActive}&include=fch_attached_image`);
  }

  selectDoorPattern = (selectedDoorPattern) => {
    const { selectedWorkingWing, dispatchGetConfiguratorInstanceComponent } = this.props;
    const wing = selectedWorkingWing === ACTIVE_WING ? 'selectedDoorPatternActive' : 'selectedDoorPatternPassive';
    dispatchGetConfiguratorInstanceComponent(NODE_DOOR_PATTERN_INSTANCE, `?filter[fch_door_pattern.id][value]=${selectedDoorPattern}&include=fch_attached_image`);
    this.setState({
      [wing]: selectedDoorPattern,
    });
  }

  selectDoorPatternInstance = (selectedDoorPatternInstance) => {
    const { selectedWorkingWing, dispatchSelectDoorPatternInstance } = this.props;
    const { selectedDoorPatternActive, selectedDoorPatternPassive } = this.state;
    const pattern = selectedWorkingWing === ACTIVE_WING ? selectedDoorPatternActive : selectedDoorPatternPassive;
    dispatchSelectDoorPatternInstance(pattern, selectedDoorPatternInstance, selectedWorkingWing);
  }

  render() {
    const {
      t,
      doorFamilyOptions,
      doorPatternOptions,
      doorPatternInstanceOptions,
      selectedDoorFamily,
      selectedWorkingWing,
      selectedDoorWingType,
      selectedDoorPatternInstanceActive, selectedDoorPatternInstancePassive,
      selectedDoorPatternInstanceImageOptions,
    } = this.props;
    const { selectedDoorPatternActive, selectedDoorPatternPassive } = this.state;

    if (!Object.keys(doorPatternOptions).length) {
      return <Spinner />;
    }

    const filteredPatternOptions = doorFamilyOptions[selectedDoorFamily].relationships.fch_door_pattern.data.map((data) => data.id);

    const filteredDoorPatternInstanceOptions = Object.keys(doorPatternInstanceOptions)
      .filter((key) => (
        find(doorPatternInstanceOptions[key].relationships.fch_door_pattern.data, {
          id: selectedWorkingWing === ACTIVE_WING ? selectedDoorPatternActive : selectedDoorPatternPassive,
        })
      ));

    return (
      <div className="steps step-02">
        {
          selectedDoorWingType === '88a0b4b0-c577-40e2-b966-16160c6c4127'
          && (
            <WingChooser />
          )
        }

        <TypeSelector
          index={1}
          title={t('stepper.step_3.page_buttons_label.pattern_type')}
          choices={filteredPatternOptions.map((key) => ({
            value: key,
            label: doorPatternOptions[key].attributes.title,
          }))}
          selectedChoice={selectedWorkingWing === ACTIVE_WING ? selectedDoorPatternActive : selectedDoorPatternPassive}
          onChange={this.selectDoorPattern}
        />

        {
          ((selectedWorkingWing === ACTIVE_WING && selectedDoorPatternActive !== null) || (selectedWorkingWing === PASSIVE_WING && selectedDoorPatternPassive))
          && (
            <TypeSelector
              index={2}
              title={t('stepper.step_3.page_buttons_label.pattern')}
              choices={filteredDoorPatternInstanceOptions.map((key) => ({
                value: key,
                url: selectedDoorPatternInstanceImageOptions[doorPatternInstanceOptions[key].relationships.fch_attached_image.data.id].attributes.uri.url,
              }))}
              choiceType="image"
              selectedChoice={selectedWorkingWing === ACTIVE_WING ? selectedDoorPatternInstanceActive : selectedDoorPatternInstancePassive}
              onChange={this.selectDoorPatternInstance}
            />
          )
        }
      </div>
    );
  }
}

Step03.propTypes = {
  doorFamilyOptions: PropTypes.shape({}).isRequired,
  doorPatternOptions: PropTypes.shape({}).isRequired,
  doorPatternInstanceOptions: PropTypes.shape({}).isRequired,
  selectedDoorPatternInstanceImageOptions: PropTypes.shape({}).isRequired,
  selectedDoorFamily: PropTypes.string.isRequired,
  selectedWorkingWing: PropTypes.string.isRequired,
  selectedDoorWingType: PropTypes.string.isRequired,
  selectedDoorPatternActive: PropTypes.string.isRequired,
  selectedDoorPatternPassive: PropTypes.string.isRequired,
  selectedDoorPatternInstanceActive: PropTypes.string.isRequired,
  selectedDoorPatternInstancePassive: PropTypes.string.isRequired,
  dispatchSelectDoorPatternInstance: PropTypes.func.isRequired,
  dispatchGetConfiguratorInstanceComponent: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  selectedDoorFamily: store.selectedOptions.selectedDoorFamily,
  selectedWorkingWing: store.selectedOptions.selectedWorkingWing,
  selectedDoorWingType: store.selectedOptions.selectedDoorWingType,
  selectedDoorPatternActive: store.selectedOptions.selectedDoorPatternActive,
  selectedDoorPatternPassive: store.selectedOptions.selectedDoorPatternPassive,
  selectedDoorPatternInstanceActive: store.selectedOptions.selectedDoorPatternInstanceActive,
  selectedDoorPatternInstancePassive: store.selectedOptions.selectedDoorPatternInstancePassive,
  doorFamilyOptions: store.availableOptions.doorFamily,
  doorPatternOptions: store.availableOptions.doorPattern,
  doorPatternInstanceOptions: store.availableOptions.doorPatternInstance,
  doorGlazingTypeOptions: store.availableOptions.doorGlazingType,
  selectedDoorPatternInstanceImageOptions: store.availableOptions.doorPatternInstanceAttachedImage,
});

const mapDispatchToProps = {
  dispatchSelectDoorPatternInstance: selectDoorPatternInstance,
  dispatchGetConfiguratorInstanceComponent: getConfiguratorComponent,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Step03));
