import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  getConfiguratorComponent,
  selectDoorFrameColor,
  selectDoorBodyColor,
  selectDoorInlay1Color,
  selectDoorInlay2Color,
} from 'actions/canvasActions';
import {
  FESTETT_DOOR_FAMILY,
  TAXONOMY_TERM_AVAILABLE_COLORS,
  ACTIVE_WING,
  inlay2Instances,
} from 'consts/consts';
import WingChooser from 'components/WingChooser/WingChooser';
import PatternPalette from 'components/PatternPalette/PatternPalette';
import Spinner from 'components/Spinner/ComponentSpinner';

class Step05 extends Component {
  componentDidMount() {
    const { dispatchGetConfiguratorInstanceComponent } = this.props;
    dispatchGetConfiguratorInstanceComponent(TAXONOMY_TERM_AVAILABLE_COLORS, '&include=fch_horizontal_image,fch_vertical_image,fch_preview_image');
  }

  selectDoorFrameColor = (selectedDoorFrameColor) => {
    const { dispatchSelectDoorFrameColor } = this.props;
    dispatchSelectDoorFrameColor(selectedDoorFrameColor);
  }

  selectDoorBodyColor = (selectedDoorBodyColorActive) => {
    const { dispatchSelectDoorBodyColor } = this.props;
    dispatchSelectDoorBodyColor(selectedDoorBodyColorActive);
  }

  selectDoorInlay1Color = (selectedDoorInlay1Color) => {
    const { dispatchSelectDoorInlay1Color } = this.props;
    dispatchSelectDoorInlay1Color(selectedDoorInlay1Color);
  }

  selectDoorInlay2Color = (selectedDoorInlay2Color) => {
    const { dispatchSelectDoorInlay2Color } = this.props;
    dispatchSelectDoorInlay2Color(selectedDoorInlay2Color);
  }

  render() {
    const {
      selectedDoorFamily, t,
      doorAvailableColors, doorAvailableColorsAttachedImage,
      selectedWorkingWing,
      selectedDoorWingType,
      selectedDoorFrameColor,
      selectedDoorBodyColorActive, selectedDoorBodyColorPassive,
      selectedDoorInlay1ColorActive, selectedDoorInlay1ColorPassive,
      selectedDoorInlay2ColorActive, selectedDoorInlay2ColorPassive,
      selectedDoorPatternActive, selectedDoorPatternPassive,
      selectedDoorPatternInstanceActive, selectedDoorPatternInstancePassive,
    } = this.props;

    if (Object.keys(doorAvailableColors).length === 0) {
      return <Spinner />;
    }

    const ralColors = [];
    const patterns = [];
    Object.keys(doorAvailableColors).forEach((key) => {
      const fchPreviewImageData = doorAvailableColors[key].relationships.fch_preview_image.data;
      if (fchPreviewImageData && doorAvailableColorsAttachedImage[fchPreviewImageData.id]) {
        patterns.push(doorAvailableColors[key]);
      } else {
        ralColors.push(doorAvailableColors[key]);
      }
    });

    return (
      <div className="steps step-02">
        <div className="configurator-block-title">{t('stepper.step_5.page_buttons_label.door_case_theme')}</div>
        <PatternPalette
          colorOptions={selectedDoorFamily === FESTETT_DOOR_FAMILY ? ralColors : []}
          patternOptions={selectedDoorFamily === FESTETT_DOOR_FAMILY ? [] : patterns}
          attachedImages={doorAvailableColorsAttachedImage}
          selectedOption={selectedDoorFrameColor}
          onSelect={this.selectDoorFrameColor}
        />

        {
          selectedDoorWingType === '88a0b4b0-c577-40e2-b966-16160c6c4127'
          && (
            <WingChooser />
          )
        }
        <div className="configurator-block-title">{t('stepper.step_5.page_buttons_label.door_sheet_theme')}</div>
        <PatternPalette
          colorOptions={selectedDoorFamily === FESTETT_DOOR_FAMILY ? ralColors : []}
          patternOptions={selectedDoorFamily === FESTETT_DOOR_FAMILY ? [] : patterns}
          attachedImages={doorAvailableColorsAttachedImage}
          selectedOption={selectedWorkingWing === ACTIVE_WING ? selectedDoorBodyColorActive : selectedDoorBodyColorPassive}
          onSelect={this.selectDoorBodyColor}
        />

        {
          (
            selectedDoorPatternActive !== 'f8ce5cbd-a10c-4b51-9bce-e69268d88cde'
            || selectedDoorPatternPassive !== 'f8ce5cbd-a10c-4b51-9bce-e69268d88cde'
          )
          && (
            <>
              <div className="configurator-block-title">{t('stepper.step_5.page_buttons_label.door_inlay_theme')}</div>
              <PatternPalette
                colorOptions={ralColors}
                patternOptions={selectedDoorFamily === FESTETT_DOOR_FAMILY ? [] : patterns}
                attachedImages={doorAvailableColorsAttachedImage}
                selectedOption={selectedWorkingWing === ACTIVE_WING ? selectedDoorInlay1ColorActive : selectedDoorInlay1ColorPassive}
                onSelect={this.selectDoorInlay1Color}
              />
            </>
          )
        }
        {
          (inlay2Instances.includes(selectedDoorPatternInstanceActive) || inlay2Instances.includes(selectedDoorPatternInstancePassive))
          && (
            <>
              <div className="configurator-block-title">{t('stepper.step_5.page_buttons_label.door_inlay2_theme')}</div>
              <PatternPalette
                colorOptions={ralColors}
                patternOptions={selectedDoorFamily === FESTETT_DOOR_FAMILY ? [] : patterns}
                attachedImages={doorAvailableColorsAttachedImage}
                selectedOption={selectedWorkingWing === ACTIVE_WING ? selectedDoorInlay2ColorActive : selectedDoorInlay2ColorPassive}
                onSelect={this.selectDoorInlay2Color}
              />
            </>
          )
        }
      </div>
    );
  }
}

Step05.propTypes = {
  doorAvailableColors: PropTypes.shape({}).isRequired,
  doorAvailableColorsAttachedImage: PropTypes.shape({}).isRequired,
  selectedDoorFamily: PropTypes.string.isRequired,
  selectedWorkingWing: PropTypes.string.isRequired,
  selectedDoorWingType: PropTypes.string.isRequired,
  selectedDoorFrameColor: PropTypes.string.isRequired,
  selectedDoorBodyColorActive: PropTypes.string.isRequired,
  selectedDoorBodyColorPassive: PropTypes.string.isRequired,
  selectedDoorInlay1ColorActive: PropTypes.string.isRequired,
  selectedDoorInlay1ColorPassive: PropTypes.string.isRequired,
  selectedDoorInlay2ColorActive: PropTypes.string.isRequired,
  selectedDoorInlay2ColorPassive: PropTypes.string.isRequired,
  selectedDoorPatternActive: PropTypes.string.isRequired,
  selectedDoorPatternPassive: PropTypes.string.isRequired,
  selectedDoorPatternInstanceActive: PropTypes.string.isRequired,
  selectedDoorPatternInstancePassive: PropTypes.string.isRequired,
  dispatchSelectDoorFrameColor: PropTypes.func.isRequired,
  dispatchSelectDoorBodyColor: PropTypes.func.isRequired,
  dispatchSelectDoorInlay1Color: PropTypes.func.isRequired,
  dispatchSelectDoorInlay2Color: PropTypes.func.isRequired,
  dispatchGetConfiguratorInstanceComponent: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  selectedDoorFamily: store.selectedOptions.selectedDoorFamily,
  selectedWorkingWing: store.selectedOptions.selectedWorkingWing,
  selectedDoorWingType: store.selectedOptions.selectedDoorWingType,
  selectedDoorFrameColor: store.selectedOptions.selectedDoorFrameColor,
  selectedDoorBodyColorActive: store.selectedOptions.selectedDoorBodyColorActive,
  selectedDoorBodyColorPassive: store.selectedOptions.selectedDoorBodyColorPassive,
  selectedDoorInlay1ColorActive: store.selectedOptions.selectedDoorInlay1ColorActive,
  selectedDoorInlay1ColorPassive: store.selectedOptions.selectedDoorInlay1ColorPassive,
  selectedDoorInlay2ColorActive: store.selectedOptions.selectedDoorInlay2ColorActive,
  selectedDoorInlay2ColorPassive: store.selectedOptions.selectedDoorInlay2ColorPassive,
  selectedDoorPatternActive: store.selectedOptions.selectedDoorPatternActive,
  selectedDoorPatternPassive: store.selectedOptions.selectedDoorPatternPassive,
  selectedDoorPatternInstanceActive: store.selectedOptions.selectedDoorPatternInstanceActive,
  selectedDoorPatternInstancePassive: store.selectedOptions.selectedDoorPatternInstancePassive,
  doorAvailableColors: store.availableOptions.doorAvailableColors,
  doorAvailableColorsAttachedImage: store.availableOptions.doorAvailableColorsAttachedImage,
  doorGlazingTypeOptions: store.availableOptions.doorGlazingType,
  doorGlazingOptions: store.availableOptions.doorGlazing,
  doorGlazingAttachedImageOptions: store.availableOptions.doorGlazingAttachedImage,
});

const mapDispatchToProps = {
  dispatchSelectDoorFrameColor: selectDoorFrameColor,
  dispatchSelectDoorBodyColor: selectDoorBodyColor,
  dispatchSelectDoorInlay1Color: selectDoorInlay1Color,
  dispatchSelectDoorInlay2Color: selectDoorInlay2Color,
  dispatchGetConfiguratorInstanceComponent: getConfiguratorComponent,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Step05));
