/* eslint-disable no-unused-vars */

import {
  CONFIGURATOR_COMPONENT_SUCCEEDED,
} from 'consts/actionTypes';
import {
  NODE_DOOR_FAMILY,
  TAXONOMY_TERM_AVAILABLE_COLORS,
  TAXONOMY_TERM_DOOR_LOCATION,
  TAXONOMY_TERM_DOOR_WING_TYPE,
  NODE_DOOR_DESIGN,
  NODE_DOOR_DESIGN_EXTRA,
  TAXONOMY_TERM_DOOR_OPENING_DIRECTION,
  NODE_DOOR_PATTERN,
  NODE_DOOR_PATTERN_INSTANCE,
  TAXONOMY_TERM_DOOR_GLAZING_TYPE,
  NODE_DOOR_GLASS_PATTERN,
  NODE_DOOR_GLAZING,
  NODE_DOOR_ACCESSORIES,
} from 'consts/consts';
import initialState from './initialState';

export const convertToChoicesObject = (choices) => {
  const choicesObject = {};
  choices.forEach((item) => { choicesObject[item.id] = item; });
  return choicesObject;
};

const availableOptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFIGURATOR_COMPONENT_SUCCEEDED: {
      const { payload } = action;
      return {
        ...state,
        doorFamily: payload.componentType === NODE_DOOR_FAMILY ? convertToChoicesObject(payload.choices) : state.doorFamily,
        doorAvailableLocation: payload.componentType === TAXONOMY_TERM_DOOR_LOCATION ? convertToChoicesObject(payload.choices) : state.doorAvailableLocation,
        doorAvailableLocationAttachedImage: payload.componentType === TAXONOMY_TERM_DOOR_LOCATION ? convertToChoicesObject(payload.included) : state.doorAvailableLocationAttachedImage,
        doorWingType: payload.componentType === TAXONOMY_TERM_DOOR_WING_TYPE ? convertToChoicesObject(payload.choices) : state.doorWingType,
        doorOpeningDirection: payload.componentType === TAXONOMY_TERM_DOOR_OPENING_DIRECTION ? convertToChoicesObject(payload.choices) : state.doorOpeningDirection,
        doorDesign: payload.componentType === NODE_DOOR_DESIGN ? convertToChoicesObject(payload.choices) : state.doorDesign,
        doorDesignExtra: payload.componentType === NODE_DOOR_DESIGN_EXTRA ? convertToChoicesObject(payload.choices) : state.doorDesignExtra,
        doorPattern: payload.componentType === NODE_DOOR_PATTERN ? convertToChoicesObject(payload.choices) : state.doorPattern,
        doorPatternInstance: payload.componentType === NODE_DOOR_PATTERN_INSTANCE ? { ...state.doorPatternInstance, ...convertToChoicesObject(payload.choices) } : state.doorPatternInstance,
        doorGlassPattern: payload.componentType === NODE_DOOR_GLASS_PATTERN ? convertToChoicesObject(payload.choices) : state.doorGlassPattern,
        doorGlassPatternAttachedImage: payload.componentType === NODE_DOOR_GLASS_PATTERN ? convertToChoicesObject(payload.included) : state.doorGlassPatternAttachedImage,
        doorGlazingType: payload.componentType === TAXONOMY_TERM_DOOR_GLAZING_TYPE ? convertToChoicesObject(payload.choices) : state.doorGlazingType,
        doorGlazing: payload.componentType === NODE_DOOR_GLAZING ? { ...state.doorGlazing, ...convertToChoicesObject(payload.choices) } : state.doorGlazing,
        doorGlazingAttachedImage: payload.componentType === NODE_DOOR_GLAZING ? { ...state.doorGlazingAttachedImage, ...convertToChoicesObject(payload.included) } : state.doorGlazingAttachedImage,
        doorAccessory: payload.componentType === NODE_DOOR_ACCESSORIES ? convertToChoicesObject(payload.choices) : state.doorAccessory,
        doorAvailableColors: payload.componentType === TAXONOMY_TERM_AVAILABLE_COLORS ? convertToChoicesObject(payload.choices) : state.doorAvailableColors,
        doorAvailableColorsAttachedImage: payload.componentType === TAXONOMY_TERM_AVAILABLE_COLORS ? convertToChoicesObject(payload.included) : state.doorAvailableColorsAttachedImage,
        doorAccessoryAttachedImage: payload.componentType === NODE_DOOR_ACCESSORIES ? convertToChoicesObject(payload.included) : state.doorAccessoryAttachedImage,
        doorPatternInstanceAttachedImage: payload.componentType === NODE_DOOR_PATTERN_INSTANCE
          ? { ...state.doorPatternInstanceAttachedImage, ...convertToChoicesObject(payload.included) }
          : state.doorPatternInstanceAttachedImage,
      };
    }

    default:
      return state;
  }
};

export default availableOptionsReducer;
